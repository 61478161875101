import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export const ExportToExcelWithRates = ({ accountName, locationName, rateCodes, fromDate, toDate, carClass, fileName, rates, rateCodeNames, account_id, location_id, rateCodeSelected, rateSource }) => {
    // console.log("rateSource:",rateSource)
    // if (Object.keys(rates).length < 1) {
    //     return

    // }
    // console.log("rates First:", rates)
    // let keyArr = Object.keys(rates)
    // for (let i = 0; i < keyArr.length; i++) {
    //     console.log('im here')
    //     let firstObj = Object.keys(rates[keyArr[i]][0]);
    //     let firstArr = rates[keyArr[i]][0];
    //     console.log("--firstArr--", firstArr);
    //     for (let j = 0; j < firstObj.length; j++) {
    //         firstArr[firstObj[j]] = (((firstArr[firstObj[j]] - (firstArr[firstObj[j]] * 26) / 100)) / keyArr[i]).toFixed(2);
    //     }
    //     console.log("--firstArr2--", firstArr);


    //     // let first_obj = Object.keys(rates[key_arr[i]][0])
    //     // for (let j = 0; j < first_obj.length; j++) {
    //     //     let first_arr = rates[key_arr[i]][0][first_obj[j]]
    //     //     console.log("--first_arr--", first_arr)
    //     //     first_arr = (((first_arr - (first_arr * 26) / 100)) / key_arr[i]).toFixed(2)
            
    //     //     console.log("--first_arr2--", first_arr)
    //     //     rates[key_arr[i]][0][first_obj[j]] = first_arr
    //     // }
    // }
    let markupRates = {};
    if (rateSource == "Expedia")
    {
        // let markupRates = {};
        let keyArr = Object.keys(rates);
        for (let i = 0; i < keyArr.length; i++) {
            // console.log('I am here');
    
            let firstObj = Object.keys(rates[keyArr[i]][0]);
            let firstArr = Object.assign({}, rates[keyArr[i]][0]); // Create a copy of the object to avoid modifying rates
            // console.log("--firstArr--", firstArr);
            for (let j = 0; j < firstObj.length; j++) {
                // console.log("keyArr[i]:",keyArr[i])
                let newValue;
                if (keyArr[i] === '7' || keyArr[i] === '28') {
                     newValue = ((firstArr[firstObj[j]] * 100) / (100 + 26)).toFixed(2);
                }else{
                     newValue = ((firstArr[firstObj[j]] * 100) / (100 + 26) / keyArr[i]).toFixed(2);
                }
    
                firstArr[firstObj[j]] = isNaN(newValue) ? "" : newValue; // Assign empty string if newValue is NaN
            }
            // console.log("--firstArr2--", firstArr);
            markupRates[keyArr[i]] = [firstArr]; // Assign the modified value to markupRates
        }
    }else{
        // let markupRates = {};
        let keyArr = Object.keys(rates);
        for (let i = 0; i < keyArr.length; i++) {
            let firstObj = Object.keys(rates[keyArr[i]][0]);
            let firstArr = Object.assign({}, rates[keyArr[i]][0]); // Create a copy of the object to avoid modifying rates
            for (let j = 0; j < firstObj.length; j++) {
                let newValue;
                if (keyArr[i] === '7' || keyArr[i] === '28') {
                    newValue = (firstArr[firstObj[j]]).toFixed(2);
                }else{
                    newValue = (firstArr[firstObj[j]] / keyArr[i]).toFixed(2);
                }

                firstArr[firstObj[j]] = isNaN(newValue) ? "" : newValue; // Assign empty string if newValue is NaN
            }
            markupRates[keyArr[i]] = [firstArr]; // Assign the modified value to markupRates
        }
    }

    
    // console.log("rates First2:", markupRates)
    // return

    const array = ['690337-YYC', '690337-YUL', '690337-YOW', '690337-YYZ', '690337-YVR'];

    // console.log(modifiedRates); // Modified rates stored in 
    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    // console.log('Before Adjustments modifiedRates:', modifiedRates)

    let calender = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let csvData = [];
    let counter = 1;

    // let adjustmentsData;
    // let myOutputName = {}; 
    // for (let key in modifiedRates) {
    //     const body_payload = {
    //         account_id: account_id.toString(),
    //         pickup_location_id: location_id.toString(),
    //         dropoff_location_id: location_id.toString(),
    //         pickup: fromDate,
    //         lor: key,
    //         ota_code: '290901',
    //         rate_code: '646351',
    //         car_class: carClass,
    //     };
    //     axios.post(baseURL + "/get_reverse_calculation_data", body_payload)
    //         .then((res2) => {
    //             // console.log("REverse Calculation Response:", res2)
    //             if (res2.data) {
    //                 adjustmentsData = res2.data;
    //                 const array = modifiedRates[key];
    //                 if (adjustmentsData) {
    //                     for (let i = 0; i < array.length; i++) {
    //                         if (typeof array[i] === "object" && array[i] !== "lowest_among_competitor") {
    //                             for (const rateKey in array[i]) {
    //                                 if (adjustmentsData.hasOwnProperty(rateKey)) {
    //                                     if (array[i][rateKey] !== "-" && !isNaN(parseFloat(array[i][rateKey])) && isFinite(array[i][rateKey])) {

    //                                         array[i][rateKey] /= adjustmentsData[rateKey];

    //                                     } else {
    //                                         array[i][rateKey] = "";
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching reverse calculation data:", error);
    //         });

    //     // Assign rates[key] to myOutputName[key] or any desired variable
    //     myOutputName[key] = JSON.parse(JSON.stringify(modifiedRates[key]));
    //     // myOutputName[key] = rates[key];
    // }


    // rates = myOutputName
    // console.log('After Adjustments myOutputName:',myOutputName)
    // console.log('After Adjustments Rates:',rates)
    // let myOutputName = {}; 
    // for (let key in markupRates) {
    //     const body_payload = {
    //         account_id: account_id.toString(),
    //         pickup_location_id: location_id.toString(),
    //         dropoff_location_id: location_id.toString(),
    //         pickup: fromDate,
    //         lor: key,
    //         ota_code: '290901',
    //         rate_code: '646351',
    //         car_class: carClass,
    //     };

    //     axios.post(baseURL + "/get_reverse_calculation_data", body_payload)
    //         .then((res2) => {
    //             if (res2.data) {
    //                 const adjustmentsData = res2.data;
    //                 console.log("adjustmentsData:",adjustmentsData)
    //                 const array = markupRates[key];
    //                 if (adjustmentsData) {
    //                     let adjustedArray = [];
    //                     for (let i = 0; i < array.length; i++) {
    //                         if (typeof array[i] === "object" && array[i] !== "lowest_among_competitor") {
    //                             let adjustedObj = {};
    //                             for (const rateKey in array[i]) {
    //                                 if (adjustmentsData.hasOwnProperty(rateKey)) {
    //                                     if (array[i][rateKey] !== "-" && !isNaN(parseFloat(array[i][rateKey])) && isFinite(array[i][rateKey])) {
    //                                         adjustedObj[rateKey] = (array[i][rateKey] / adjustmentsData[rateKey]).toFixed(2);
    //                                     } else {
    //                                         adjustedObj[rateKey] = "";
    //                                     }
    //                                 }
    //                             }
    //                             adjustedArray.push(adjustedObj);
    //                         }
    //                     }
    //                     myOutputName[key] = adjustedArray;
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching reverse calculation data:", error);
    //         });
    // }

    let promises = []; // Array to store all promises from Axios requests
    let myOutputName = {}; 
    let selectedRateCode
    let selectedRateCodeName

    // if (rateCodeSelected == 'Pay On Arrival'){
    //     selectedRateCode = '778288'
    //     selectedRateCodeName = 'Net Rate POA Non-Inclusive'
    // }else{
    //     selectedRateCode = '646351'
    //     selectedRateCodeName = 'PP Non-Inclusive'
    // }
    selectedRateCode = '778288'
    selectedRateCodeName = 'Net Rate POA Non-Inclusive'
    let otaCode
    if (rateSource == "Expedia"){
        otaCode = '290901'
    }else{
        otaCode = '497248'
    }
    // console.log("rateCodeSelected:",rateCodeSelected)
    // console.log("selectedRateCode:",selectedRateCode)
    // console.log("rateSource:",rateSource)
    // console.log("otaCode:",otaCode)

    for (let key in markupRates) {
        const body_payload = {
            account_id: account_id.toString(),
            pickup_location_id: location_id.toString(),
            dropoff_location_id: location_id.toString(),
            pickup: fromDate,
            lor: key,
            ota_code: otaCode,
            rate_code: selectedRateCode,
            rate_code: selectedRateCode,
            car_class: carClass,
        };

        // Make the Axios POST request and push the promise into the promises array
        const promise = axios.post(baseURL + "/get_reverse_calculation_data", body_payload)
            .then((res2) => {
                if (res2.data) {
                    const adjustmentsData = res2.data;
                    console.log("adjustmentsData:", adjustmentsData);

                    const array = markupRates[key];
                    let adjustedArray = [];

                    for (let i = 0; i < array.length; i++) {
                        if (typeof array[i] === "object" && array[i] !== "lowest_among_competitor") {
                            let adjustedObj = {};

                            for (const rateKey in array[i]) {
                                if (adjustmentsData.hasOwnProperty(rateKey)) {
                                    if (array[i][rateKey] !== "-" && !isNaN(parseFloat(array[i][rateKey])) && isFinite(array[i][rateKey])) {
                                        adjustedObj[rateKey] = (array[i][rateKey] / adjustmentsData[rateKey]).toFixed(2);
                                    } else {
                                        adjustedObj[rateKey] = "";
                                    }
                                }
                            }

                            adjustedArray.push(adjustedObj);
                        }
                    }

                    // Assign the adjusted array to myOutputName
                    myOutputName[key] = adjustedArray;
                } else {
                    console.error("Error: No data returned from the server");
                }
            })
            .catch((error) => {
                console.error("Error fetching reverse calculation data:", error);
            });

        promises.push(promise); // Push the current promise into the promises array
    }

    // Wait for all promises to resolve
    // Promise.all(promises)
    //     .then(() => {
    //         console.log("All Axios requests completed successfully.");
    //         console.log("myOutputName:", myOutputName);
    //     })
    //     .catch((error) => {
    //         console.error("Error occurred while waiting for Axios requests:", error);
    //     });
    (async () => {
        try {
            await Promise.all(promises);
            // console.log("All Axios requests completed successfully.");
            // console.log("myOutputName:", myOutputName);
        } catch (error) {
            console.error("Error occurred while waiting for Axios requests:", error);
        }
    
        // Code here will execute after all Axios requests have completed
        // console.log("myOutputName22:", myOutputName);

        let i = new Date(fromDate).valueOf();
        let date = formatDate(new Date(i))
        for (let j = 0; j < rateCodes.length; j++) {
            for (let k = 0; k < carClass.length; k++) {
                let key = date + "_" + rateCodes[j] + "_" + carClass[k];
                csvData.push({
                    "Sl. No.": counter++,
                    "Account Name": accountName,
                    "Location Name": locationName,
                    "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
                    "Rate Code": selectedRateCode,
                    "Rate Code Name": selectedRateCodeName,
                    "Car Class": carClass[k],
                    "1 Day": myOutputName[1] != undefined ? myOutputName[1][0][carClass[k]] == '-' ? "" : myOutputName[1][0][carClass[k]] ?? '' : "",
                    "2 Day": myOutputName[2] != undefined ? myOutputName[2][0][carClass[k]] == '-' ? "" : myOutputName[2][0][carClass[k]] ?? '' : "",
                    "3 Day": myOutputName[3] != undefined ? myOutputName[3][0][carClass[k]] == '-' ? "" : myOutputName[3][0][carClass[k]] ?? '' : "",
                    "4 Day": myOutputName[4] != undefined ? myOutputName[4][0][carClass[k]] == '-' ? "" : myOutputName[4][0][carClass[k]] ?? '' : "",
                    "5 Day": myOutputName[5] != undefined ? myOutputName[5][0][carClass[k]] == '-' ? "" : myOutputName[5][0][carClass[k]] ?? '' : "",
                    "6 Day": myOutputName[6] != undefined ? myOutputName[6][0][carClass[k]] == '-' ? "" : myOutputName[6][0][carClass[k]] ?? '' : "",
                    "Daily X-Day": "",
                    "Weekly": myOutputName[7] != undefined ? myOutputName[7][0][carClass[k]] == '-' ? "" : myOutputName[7][0][carClass[k]] ?? '' : "",
                    "Weekly X-Day": '',
                    "Monthly": myOutputName[28] != undefined ? myOutputName[28][0][carClass[k]] == '-' ? "" : myOutputName[28][0][carClass[k]] ?? '' : "",
                    "Monthly X-Day": '',
                    "Weekend": '',
                    "Hourly": '',
                    "Minute": '',
                    "Per Mile/Km": ''
                })
            }
        }
    })();









   


    const downloadExcelFileWithRates = async () => {
        // console.log("carClass:",carClass)
        // console.log("rates:",rates)
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Rate Update with Rates': ws }, SheetNames: ['Rate Update with Rates'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    return (
        <button className="btn btn-dark" type="button" style={{ color: 'white' }} onClick={downloadExcelFileWithRates}>
            Download Excel Rates
        </button>
    )
}