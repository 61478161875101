import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import { isInteger } from 'formik';
import moment from 'moment';

export default function UpdateForm({ accessType }) {


    const enabledLocationsSubmit = (e) => {

        // const submitHandler = (e) => {
        e.preventDefault();
        axios
            .post(baseURL + "/save_automation_enabled_info", locationNameOptions)
            .then((res) => {
                Swal.fire({
                    position: "middle",
                    icon: "success",
                    title: "Selected Locations Enabled Successfully",
                    showConfirmButton: false,
                    timer: 3000,
                });
            });
        console.log(locationNameOptions);

    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationLength, setLocationLength] = useState(0);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [enabledLocations, setEnabledLocations] = useState([]);
    const [clickPerDay, setClickPerDay] = useState([]);
    const [retrivedArray, setRetrivedArray] = useState([]);
    // const [locationNameOptions, setLocationNameOptions] = useState([{}]);
    const [locationNameOptions, setLocationNameOptions] = useState([]);
    // const [locationNameOptions, setLocationNameOptions] = useState([
    //     // Example initial state, you can replace this with your actual data
    //     { value: 'Location 1', isChecked: false, inputValue: '' },
    //     { value: 'Location 2', isChecked: false, inputValue: '' },
    // ]);

    // const [locations, setLocations] = useState(
    //     locationNameOptions.map((item) => ({
    //         ...item,
    //         inputValue: '',
    //     }))
    // );

    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [locationSelectionAccess, setLocationSelectionAccess] = useState("No")


    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    // const handleCheckboxChange = (value) => {
    //     const updatedOptions = locationNameOptions.map((option) =>
    //         option.value === value ? { ...option, isChecked: !option.isChecked } : option
    //     );
    //     setLocationNameOptions(updatedOptions);
    //     setEnabledLocations(updatedOptions)
    // };

    const handleCheckboxChange = (value) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, isChecked: !option.isChecked } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions.filter(option => option.isChecked));
    };

    const handleInputChange = (value, inputValue) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, inputValue: inputValue } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    };
    console.log('locationNameOptions',locationNameOptions)
    console.log('setEnabledLocations',enabledLocations)
    // const handleInputChange = (index, value) => {
    //     console.log('index',index)
    //     console.log('value',value)
    //     // const newLocations = [...locations];
    //     // newLocations[index].inputValue = value;
    //     // setLocations(newLocations);
    // };

    // const handleCheckboxChange = (index) => {
    //     const newLocations = [...locations];
    //     newLocations[index].isChecked = !newLocations[index].isChecked;
    //     setLocations(newLocations);

    // };

    // const handleInputChange = (index, value) => {
    //     const newLocations = [...locations];
    //     newLocations[index].inputValue = value;
    //     setLocations(newLocations);
    // };



    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['EnableAutomation_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];

            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('');
            setLocationId('');
            setLocationDetail([{}]);
            //const [locationDetail, setLocationDetail] = useState([{}]);
            // setLocationDetail('');
            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    console.log("allTemp")
                    console.log(allTemp)
                    setLocationLength(allTemp.length);
                    setLocationDetail(allTemp);
                    // const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                    const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false, inputValue: '' }));
                    setLocationNameOptions(updatedLocationNameOptions);

                    if (allTemp.length > 0) {
                        const locationNamesArray = allTemp.map((item) => item.location_id);
                        axios
                            .get(baseURL + "/get_automation_enabled_infos/" + locationNamesArray)
                            .then((res) => {
                                setRetrivedArray(res.data);
                            });
                    }

                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                            const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                            setLocationNameOptions(updatedLocationNameOptions);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                            const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                            setLocationNameOptions(updatedLocationNameOptions);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                    const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                                    setLocationNameOptions(updatedLocationNameOptions);

                                }
                            }
                            )
                    }
                });
            setLocationSelectionAccess("Yes");

        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }
    }, [locationName])

    useEffect(() => {
        const enabledLocationNames = retrivedArray
            .filter((item) => item.enabled)
            .map((item) => item.location_id);

        const updatedLocationNameOptions = locationNameOptions.map((item) => ({
            ...item,
            isChecked: enabledLocationNames.includes(item.value),
            inputValue: retrivedArray.find((retrievedItem) => retrievedItem.location_id === item.value)?.inputValue || ''
        }));
        setLocationNameOptions(updatedLocationNameOptions);
    }, [retrivedArray])

    // const updatedLocationNameOptions = locationNameOptions.map((item) => ({
    //     ...item,
    //     isChecked: enabledLocationNames.includes(item.value),
    //     inputValue: retrivedArray.find((retrievedItem) => retrievedItem.location_id === item.value)?.inputValue || ''
    // }));


    // Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));
    console.log('locationNameOptions',locationNameOptions)




    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    return (
        <>
            <div className="row pt-2 mx-2">
                <div className="col-md-10">
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                    </div>
                    {
                        locationSelectionAccess === 'Yes' && locationLength > 0 ?
                            <div>
                                {/* <div className="row pt-3 ">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="rateCode" className="form-label" style={{ marginTop: 5 }}>
                                            Enabled Locations
                                        </label>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <table className="table table-borderless text-left">
                                            <tbody>
                                                {locationNameOptions.map((item, index) => (
                                                    <tr key={index}>
                                                        <td style={{ width: '50px' }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.isChecked}
                                                                onChange={() => handleCheckboxChange(item.value)}
                                                            />
                                                        </td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                                <div className="row pt-3">
            <div className="col-md-2 mb-3">
                <label htmlFor="rateCode" className="form-label" style={{ marginTop: 5 }}>
                    Enabled Locations
                </label>
            </div>
            <div className="col-md-6 mb-3">
                <table className="table table-borderless text-left">
                    <thead>
                        <tr>
                            {/* <th style={{ width: '50px' }}>Select</th> */}
                            <th></th>
                            <th>Location</th>
                            <th style={{marginLeft:'50px'}}>Clicks / Per Day</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationNameOptions.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={item.isChecked}
                                        onChange={() => handleCheckboxChange(item.value)}
                                        // onChange={() => handleCheckboxChange(index)}
                                    />
                                </td>
                                <td>{item.value}</td>
                                <td style={{textAlign:'center'}}>
                                    <input
                                        type="text"
                                        style={{width:'60%', textAlign:'center'}}
                                        className="form-control"
                                        value={item.inputValue}
                                        // onChange={(e) => this.handleChange(item.value, e.target.value)}
                                        onChange={(e) => handleInputChange(item.value, e.target.value)}
                                        disabled={!item.isChecked}
                                        placeholder="Enter value"
                                        // pattern="[0-9]*"
                                        // inputMode="numeric"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
                                
                                

                                {accessType === 'View' ? "" :
                                    <div className="col-md-2" style={{ marginLeft: '5px' }}>
                                        <button type="submit" className="btn btn-success" onClick={enabledLocationsSubmit} >Submit </button>
                                    </div>
                                }
                            </div> : ''
                    }
                </div>
            </div>
        </>
    )
}