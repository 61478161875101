import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import "antd/dist/antd.css";
import { Calendar, Empty, DatePicker } from 'antd';
import './RateUpdate.css'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import { ExportToExcel } from './ExportToExcel'
import { ExportToExcelWithRates } from './ExportToExcelWithRates';
import ExcelUpload from './ExcelUpload';
import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import { isEmpty } from 'lodash';
import ProgressBar from './ProgressBar';
// import './ProgressBar.css';
// import ProgressBar from 'react-progress-bar';
import './loader.css';
// import Loader from './Loader';
// import Loader from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';


export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const formatDate_Modal = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    // Instant Automation Data

    // const LoadingIcon = () => {
    //     return (
    //         <div className="loading-spinner-container">
    //             <div className="loading-spinner">
    //                 <div className="spinner outer">
    //                 <div className="spinner inner">
    //                     <div className="spinner eye"></div>
    //                 </div>
    //                 </div>
    //             </div>
    //             <div className="loading-text">
    //                 <p><b>Please wait - Rate Automation is in Progress. Please do not click outside or close this screen in the meantime</b></p>
    //             </div>
    //         </div>
    //     );
    //   };

    const [loading2, setLoading2] = useState(false); 
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [lgShow5, setLgShow5] = useState(false);

    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');
    const [selectedRateParity, setSelectedRateParity] = useState('Static');
    const [selectedApplicableDays, setSelectedApplicableDays] = useState('');
    const [selectedAdvanceDays, setSelectedAdvanceDays] = useState('');
    const applicableDaysOptions = ['1', '2', '3', '4', '5', '6', '7'];
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    // const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest','Lowest among all the Car Classes', 'Custom', 'Leave as it is']; // Replace this with your main dropdown options
    const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest','Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport','Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal','Lowest among all the Car Classes', 'Custom', 'Leave as it is'];
    const changeOptions = ['Dollar ($)', 'Percentage (%)']; // Replace this with your additional dropdown options
    const [selectedValues, setSelectedValues] = useState([]);
    const [sippValues, setSelectedSippValues] = useState([]);
    const [sippValues2, setSelectedSippValues2] = useState([]);
    const [sippValues3, setSelectedSippValues3] = useState([]);
    const [rangeValues, setSelectedRangeValues] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    const [selectedChange, setSelectedChange] = useState([]);
    const [timeDuration, setTimeDuration] = useState(0);
    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('Expedia');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState();
    // const [pricelineOptions, setPricelineOptions]  = useState(['Compactttt', 'Economy', 'Standard','Mid-Size', 'Full-Size', 'Premium','Luxury', 'Mini-Van']);
    const [expediaOptions, setExpediaOptions]  = useState(['Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Compact SUV', 'Midsize SUV', 'Standard SUV', 'Standard Elite SUV', 'Fullsize SUV', 'Premium SUV', 'Luxury SUV', 'Special SUV', 'Compact Elite SUV', 'Premium Elite SUV', 'Van', 'Pickup', 'Sports Car', 'Other', 'Standard Open Air All-Terrain', 'Fullsize Open Air All-Terrain']);
    const [pricelineOptions, setPricelineOPtions]  = useState(['Car','Economy', 'Compact','MidSize', 'Standard', 'Full-Size','Premium', 'Luxury Car', 'Sports Car', 'SUV', 'Compact SUV', 'Midsize-SUV', 'Standard SUV', 'Standard Elite SUV', 'Full-Size SUV','Full-Size Elite SUV', 'Premium SUV', 'Premium Crossover', 'Luxury SUV', 'Van', 'Minivan', "Supplier's Choice", 'Pickup Truck','Electric', 'Convertible']);
    const [lgShow1, setLgShow1] = useState(false);
    const [copyToDate, setCopyToDate] = useState(new Date());
    const [checkBoxSelctedToCopy, setCheckBoxSelctedToCopy] = useState({});
    const [rateCodeForCopyRateCodeID, setRateCodeForCopyRateCodeID] = useState("");

    const rateSourceOptions = [
        { value: "Expedia", label: "Expedia" },
        { value: "Priceline", label: "Priceline" }
      ];

    const competitionOptions = [
        { value: "SIPP CODE", label: "SIPP CODE" },
        { value: "Car Category", label: "Car Category" }
    ];  

    const parityOptions = [
        { value: "Static", label: "Static" },
        { value: "Multiple of LOR", label: "Multiple of LOR" }
    ];

    const handleRateSourceOption2 = (value) => {
        if(value == "Expedia" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else{
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }
        setSelectedRateSourceOption(value);
    };

    const handleRateSourceOption = (value) => {
        if(value == "Expedia" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(expediaOptions)
            setCarDetailsInOption2(expediaOptions)
        }else if(value == "Expedia" && selectedCompetitionOption !== "Car Category"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Priceline" && selectedCompetitionOption !== "SIPP CODE"){
            setCarDetailsInOption1(pricelineOptions)
            setCarDetailsInOption2(pricelineOptions)
        }else if(value == "Priceline" && selectedCompetitionOption !== "Car Category"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }    
        setSelectedRateSourceOption(value);
    };

    const handleCompetitionOption = (value) => {
        setSelectedCompetitionOption(value);
        if(value == "SIPP CODE"){
            setCarDetailsInOption1(carDetailsInOptionConstant)
            setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Car Category"){
            if(selectedRateSourceOption == "Expedia"){
                setCarDetailsInOption1(expediaOptions)
                setCarDetailsInOption2(expediaOptions)
            }else if (selectedRateSourceOption == "Priceline"){
                setCarDetailsInOption1(pricelineOptions)
                setCarDetailsInOption2(pricelineOptions)
            }    
        }
    };

    // const handleRateSourceOption = (value) => {
    //     if(value == "Priceline"){
    //         setSelectedCompetitionOption("SIPP CODE");
    //         setCarDetailsInOption1(carDetailsInOptionConstant)
    //     }
    //     if(value == "Priceline" && selectedCompetitionOption !== "SIPP CODE" ){
    //         setCarDetailsInOption1(carDetailsInOptionConstant)
    //         // setCarDetailsInOption1(pricelineOptions)
    //     }else if(value == "Expedia" && selectedCompetitionOption !== "SIPP CODE"){
    //         setCarDetailsInOption1(expediaOptions)
    //     }else{
    //         setCarDetailsInOption1(carDetailsInOptionConstant)
    //     }
    //     setSelectedRateSourceOption(value);
    // };

    // const handleCompetitionOption = (value) => {
    //     setSelectedCompetitionOption(value);
    //     if(value == "SIPP CODE"){
    //         setCarDetailsInOption1(carDetailsInOptionConstant)
    //     }else if(value == "Car Category"){
    //         if(selectedRateSourceOption == "Expedia"){
    //             setCarDetailsInOption1(expediaOptions)
    //         }else if (selectedRateSourceOption == "Priceline"){
    //             setSelectedCompetitionOption("SIPP CODE");
    //             setCarDetailsInOption1(carDetailsInOptionConstant)
    //         }
    //     }
    // };


    const hours = [
        { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
        { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
        { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
        { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
        { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
        { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
        { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },
      ];
    const inAdvanceOptions = {
        '0': 'Current Day',
        '1': '+1 Day to Current',
        '2': '+2 Day to Current',
        '3': '+3 Day to Current',
        '4': '+4 Day to Current',
        '5': '+5 Day to Current',
        '6': '+6 Day to Current'
        };

    const handleSelectAll = () => {
        const areAllSelected = Object.values(numbers).every((value) => value);
        const updatedNumbers = {};

        for (const key in numbers) {
            updatedNumbers[key] = !areAllSelected;
        }

        setNumbers(updatedNumbers);
    };
    const handleComptitorSippCodes = (index, value) => {
        setSelectedSippValues((prevValues) => {
            const updatedValues2 = [...prevValues];
            updatedValues2[index] = { compSippValues: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes2 = (index, value) => {
        setSelectedSippValues2((prevValues2) => {
            const updatedValues2 = [...prevValues2];
            updatedValues2[index] = { compSippValues2: value };
            return updatedValues2;
        });
    };

    const handleComptitorSippCodes3 = (index, value) => {
        setSelectedSippValues3((prevValues3) => {
            const updatedValues2 = [...prevValues3];
            updatedValues2[index] = { compSippValues3: value };
            return updatedValues2;
        });
    };

    const handleStrategy = (index, value) => {
        setSelectedStrategy((prevValues3) => {
            const updatedValues3 = [...prevValues3];
            updatedValues3[index] = { strategyValues: value };
            return updatedValues3;
        });

        if (value != 'custom') {
            setSelectedCompetitor((prevValues4) => {
                const updatedValues4 = [...prevValues4];
                updatedValues4[index] = { competitorValues: '' };
                //updatedValues5[mainIndex].additionalDropdown = value;
                return updatedValues4;
            });

            setSelectedAmount((prevValues6) => {
                const updatedValues6 = [...prevValues6];
                updatedValues6[index] = { amountValues: '' };
                return updatedValues6;
            });

            setSelectedChange((prevValues5) => {
                const updatedValues5 = [...prevValues5];
                updatedValues5[index] = { changeValues: '' };
                //updatedValues5[mainIndex].additionalDropdown = value;
                return updatedValues5;
            });

        }
    };

    const handleAmount = (index, value) => {
        setSelectedAmount((prevValues6) => {
            const updatedValues6 = [...prevValues6];
            updatedValues6[index] = { amountValues: value };
            return updatedValues6;
        });
    };

    const handleChange = (index, value) => {
        setSelectedChange((prevValues5) => {
            const updatedValues5 = [...prevValues5];
            updatedValues5[index] = { changeValues: value };
            //updatedValues5[mainIndex].additionalDropdown = value;
            return updatedValues5;
        });
    };

    const handleCompetitor = (index, value) => {
        setSelectedCompetitor((prevValues4) => {
            const updatedValues4 = [...prevValues4];
            updatedValues4[index] = { competitorValues: value };
            //updatedValues5[mainIndex].additionalDropdown = value;
            return updatedValues4;
        });
    };    
    const handleNumberSelection = (number) => {
        setNumbers({
            ...numbers,
            [number]: !numbers[number], // Toggle the value for the clicked number
        });
    };
        

    const handlePickUpTime = (value) => {
        setSelectedPickUpTime(value);
    };
    const handleParity = (value) => {
        setSelectedRateParity(value);
    };
    const handleApplicableDays = (value) => {
        setSelectedApplicableDays(value);
    };
    const handleDaysInAdvance = (value) => {
        setSelectedAdvanceDays(value);
    };
     

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [updatedValues3, setUpdatedValues3] = useState([])

    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    const [locationNameForCopyRate, setLocationNameForCopyRate] = useState('')
    const [locationIDforCopyRate, setLocationIDforCopyRate] = useState('')
    const [rateCodeForCopyRate, setRateCodeForCopyRate] = useState('')

    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [table, setTable] = useState([])
    

    const daysNameTable = [['Monday', 'Friday'], ['Tuesday', 'Saturday'], ['Wednesday', 'Sunday'], ['Thursday', null]]
    const [daysNameHash, setDaysNameHash] = useState({ "Monday": false, "Tuesday": false, 'Wednesday': false, 'Thursday': false, 'Friday': false, 'Saturday': false, 'Sunday': false })
    const [lorsHash, setLorsHash] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'X-Day': false })

    const [carDetails, setCarDetails] = useState([])
    const [carDetailsInOption1, setCarDetailsInOption1] = useState([])
    const [carDetailsInOption2, setCarDetailsInOption2] = useState([])

    const [carDetailsInOptionConstant, setCarDetailsInOptionConstant] = useState([])
    const [fleetStandardCarDetails, setFleetStandardCarDetails] = useState([])
    const [gridDetails, setGridDetails] = useState({})

    const [carDetailsInModal, setCarDetailsInModal] = useState([])
    const [gridDetailsInModal, setGridDetailsInModal] = useState({})
    const [gridDetailsInModalForDateRange, setGridDetailsInModalForDateRange] = useState({})
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [selectAllDaysFlag, setSelectAllDaysFlag] = useState(true)

    const [preloadFlag, setPreloadFlag] = useState(true)
    const [locationEnabledForAutomation, setLocationEnabledForAutomation] = useState("No")

    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const [instantFromDate, setInstantFromDate] = useState(new Date());
    const [instantToDate, setInstantToDate] = useState(new Date());

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [weekendRatesShowFlag, setWeekendRatesShowFlag] = useState(false)

    const [rateCodesSelected, setRateCodesSelected] = useState('')
    const [lgShow, setLgShow] = useState(false);

    const [dateArr, setDateArr] = useState([])
    const [selectedDatePicker, setSelectedDatePicker] = useState('')

    const [copyRateFlag, setCopyRateFlag] = useState(true)

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    

    const [ratesForExcelDownloadWithRates, setRatesForExcelDownloadWithRates] = useState({});

    const [copyRateAccess, setCopyRateAccess] = useState("Yes");
    const [daysApplicableAccess, setDaysApplicableAccess] = useState("Yes")
    const [excelUploadAccess, setExcelUploadAccess] = useState("Yes")
    const [rateCodeSelectionAccess, setRateCodeSelectionAccess] = useState("Yes")

    const [rateCodeNames, setRateCodeNames] = useState({})

    const [reverseCalculatorGrid, setReverseCalculatorGrid] = useState({})
    const [reverseCalculatorErrorMessage, setReverseCalculatorErrorMessage] = useState('')
    const [competitorsGrid, setCompetitorsGrid] = useState([["0"]]);


    const [independentTaxName, setIndependentTaxName] = useState("");
    const [independentType, setIndependentType] = useState("");
    const [independentCurrency, setIndependentCurrency] = useState("");
    const [independentAmount, setIndependentAmount] = useState("");
    const [baseRateTaxName, setBaseRateTaxName] = useState("");
    const [baseRateType, setBaseRateType] = useState("Per Rental");
    const [baseRatePercentage, setBaseRatePercentage] = useState("");
    const [calculatedTaxName, setCalculatedTaxName] = useState("");
    const [calculatedPercentage, setCalculatedPercentage] = useState("");
    const [updateFlag1, setUpdateFlag1] = useState(false);
    const [updateFlag2, setUpdateFlag2] = useState(false);
    const [updateFlag3, setUpdateFlag3] = useState(false);
    const [progress, setProgress] = useState(0);

    const [selectedNumbers, setSelectedNumbers] = useState([]);
    


    const handleAutomationSubmit = async (e) => {
        e.preventDefault();
        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }
        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }
        const hasTrueValueForLor = Object.values(numbers).some((value) => value === true);
        if (selectedPickUpTime === "") {
            Swal.fire('Please select a PickUp Time');
            return;
        }
        if (!hasTrueValueForLor) {
            Swal.fire("Please select atleast one Lor");
            return;
        }
        
        let carDetails_d1 = []
        for(let i=0; i<carDetails.length; i++){
            
            carDetails_d1.push(carDetails[i][0])
        }
        let sippValues_d1 = []
        console.log('sippValues',sippValues)
        for(let i=0; i<sippValues.length; i++){
            if(sippValues.length > 0)
            {
                sippValues_d1.push(sippValues[i]['compSippValues'])
            }
        }
        console.log('sippValues_d14444',sippValues_d1)

        let sippValues_d2 = []
        for(let i=0; i<sippValues2.length; i++){
            if(sippValues2.length > 0)
            {                 
                if (sippValues2[i] && sippValues2[i].hasOwnProperty('compSippValues2')) { 
                    if (sippValues2[i]['compSippValues2'] != '' && sippValues2[i]['compSippValues2'] != undefined) {   
                        sippValues_d2.push(sippValues2[i]['compSippValues2'])
                    }else{
                        sippValues_d2.push('empty')
                    }
                }else{
                    sippValues_d2.push('empty')
                }
            }
        }

        let sippValues_d3 = []
        for(let i=0; i<sippValues3.length; i++){
            if(sippValues3.length > 0)
            {                 
                if (sippValues3[i] && sippValues3[i].hasOwnProperty('compSippValues3')) { 
                    if (sippValues3[i]['compSippValues3'] != '' && sippValues3[i]['compSippValues3'] != undefined) {   
                        sippValues_d3.push(sippValues3[i]['compSippValues3'])
                    }else{
                        sippValues_d3.push('empty')
                    }
                }else{
                    sippValues_d3.push('empty')
                }
            }
        }

        let modifiedMinor1;
        if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Expedia'){
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor1 = sippValues_d1.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d1 = modifiedMinor1

        let modifiedMinor2;
        if (selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor2 = sippValues_d2.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d2 = modifiedMinor2

        let modifiedMinor3;
        if (selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            expediaOptions.includes(minorValue) ? minorValue : ''
            );
        }else if (selectedCompetitionOption == "Car Category" && selectedRateSourceOption == 'Priceline'){
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            pricelineOptions.includes(minorValue) ? minorValue : ''
            );
        }else{
            modifiedMinor3 = sippValues_d3.map((minorValue) =>
            expediaOptions.includes(minorValue) ? '' : minorValue
            );
        }
        sippValues_d3 = modifiedMinor3
   
        let selectedStrategy_d1 = []
        for(let i=0; i<selectedStrategy.length; i++){
            if(selectedStrategy.length > 0)
            {
                selectedStrategy_d1.push(selectedStrategy[i]['strategyValues'])
            }
        }

        let selectedCompetitor_d1 = []
        for(let i=0; i<selectedCompetitor.length; i++){
            if(selectedCompetitor.length > 0)
            {
                selectedCompetitor_d1.push(selectedCompetitor[i]['competitorValues'])
            }
        }

        let selectedChange_d1 = []
        for(let i=0; i<selectedChange.length; i++){
            if(selectedChange.length > 0)
            {
                selectedChange_d1.push(selectedChange[i]['changeValues'])
            }
        }

        let selectedAmount_d1 = []
        for(let i=0; i<selectedAmount.length; i++){
            if(selectedAmount.length > 0)
            {
                if(selectedAmount[i]['amountValues'] == ''){
                    selectedAmount_d1.push('0')
                }else{
                    selectedAmount_d1.push(selectedAmount[i]['amountValues'])
                }
            }
        }
        let competitorCount = selectedCompetitor_d1.filter(item => item !== '').length;
        let changeCount = selectedChange_d1.filter(item => item !== '').length;
        let amountCount = selectedAmount_d1.filter(item => item !== '').length;
        const countOfCustom = selectedStrategy_d1.filter(item => item === 'Custom').length;
        const countOfStrategy = selectedStrategy_d1.filter(item => item === '').length;
        console.log('sippValues_d1',sippValues_d1)
        const nonEmptySippCount = sippValues_d1.filter(car => car !== '').length;    

        if((nonEmptySippCount != carDetails.length) || (selectedStrategy_d1.length != carDetails.length) || (countOfCustom != competitorCount) || (countOfCustom != changeCount) || (countOfCustom != amountCount) || (countOfStrategy > 0)){
            console.log("im INSIDE")
            for(let i=0; i<carDetails_d1.length; i++)
            {
                if(sippValues_d1.length > 0)
                {
                    console.log(selectedStrategy_d1[i])
                    if(sippValues_d1[i] == '' || sippValues_d1[i] == undefined)
                    {
                        if(selectedStrategy_d1[i] === "Leave as it is"){
                            if(selectedCompetitionOption === "SIPP CODE"){
                                sippValues_d1[i] = 'CCAR';
                            }else{
                                sippValues_d1[i] = 'Mini';
                            }                            
                        }else{
                            Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+"");
                            return;
                        }
                    }    
                }else{
                       Swal.fire("Please select Competes sipp code for "+carDetails_d1[i]+" ");
                       return;
                }
                if(selectedStrategy_d1.length > 0)
                {
                    if(selectedStrategy_d1[i] == '' || selectedStrategy_d1[i] == undefined)
                    {
                        Swal.fire("Please select Strategy for "+carDetails_d1[i]+" ");
                        return;
                    } else if(selectedStrategy_d1[i] == 'Custom'){
                        if(selectedCompetitor_d1[i] == '' || selectedCompetitor_d1[i] == undefined)
                        {
                            Swal.fire("Please select Competitor for "+carDetails_d1[i]+" ");
                            return;
                        }else if(selectedChange_d1[i] == '' || selectedChange_d1[i] == undefined)
                        {   
                            selectedChange_d1[i] = 'Dollar ($)'
                            // Swal.fire("Please select Dollar/Percentage for "+carDetails_d1[i]+" ");
                            // return;  
                        }else if(selectedAmount_d1[i] == '' || selectedAmount_d1[i] == undefined)
                        {
                            Swal.fire("Please select Range value for "+carDetails_d1[i]+" ");
                            return;
                        }
                    }else if(selectedStrategy_d1[i] != 'Custom' && selectedStrategy_d1[i] != 'Leave as it is' ){
                        if(selectedChange_d1[i] == '' || selectedChange_d1[i] == undefined)
                        {  
                            selectedChange_d1[i] = 'Dollar ($)'
                            // Swal.fire("Please select Dollar/Percentage for "+carDetails_d1[i]+" ");
                            // return;
                        }   
                    }
                }else
                {
                        Swal.fire("Please select strategy for "+carDetails_d1[i]+" ");
                        return;
                }
            }
        }
        console.log(sippValues_d1)    
        console.log(sippValues_d2)    
        const secondArray = sippValues_d2.slice();
        console.log(secondArray)  
        const thirdArray = sippValues_d3.slice();
        carDetails_d1.forEach((value, index) => {
            if (sippValues_d2[index] ===  undefined) {
                secondArray[index] = 'empty'
            }
        });
        console.log(secondArray)  

        carDetails_d1.forEach((value, index) => {
            if (sippValues_d3[index] ===  undefined ) {
                thirdArray[index] = 'empty'
            }
        });
         
        const date1 = new Date(instantFromDate);
        const date2 = new Date(instantToDate);
        const timeDifference = date2 - date1;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const finalDaysDifference = daysDifference + 1
        const trueCount = Object.values(numbers).filter(value => value === true).length;
        const timeDurationCount = (0.9*finalDaysDifference) * trueCount
        const timeDurationValue = timeDurationCount * 40000

        const truncated_comp_sipp_code1 = sippValues_d1.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code2 = secondArray.slice(0, carDetails_d1.length);
        const truncated_comp_sipp_code3 = thirdArray.slice(0, carDetails_d1.length);
        const truncated_strategy = selectedStrategy_d1.slice(0, carDetails_d1.length);
        const truncated_competitor = selectedCompetitor_d1.slice(0, carDetails_d1.length);
        const truncated_change = selectedChange_d1.slice(0, carDetails_d1.length);
        const truncated_amount = selectedAmount_d1.slice(0, carDetails_d1.length);
        setLoading2(true)
        setButtonDisabled(true);
        setTimeDuration(timeDurationValue)
        const body_payload = 
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            rateSourceSelected: selectedRateSourceOption,
            competitionSelected: selectedCompetitionOption,
            paritySelected: selectedRateParity,
            lor: numbers,
            sipp_code: carDetails_d1,
            comp_sipp_code: truncated_comp_sipp_code1,
            comp_sipp_code2: truncated_comp_sipp_code2,
            comp_sipp_code3: truncated_comp_sipp_code3,
            strategy: truncated_strategy,
            selected_competitor: truncated_competitor,
            selected_change: truncated_change,
            amount: truncated_amount,
            from_date:new Date(instantFromDate).toDateString(),
            to_date: new Date(instantToDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        const res2 = await axios.post(baseURL + "/save_automation_instant_info", body_payload);
        //   .then((res2) => {
            setTimeDuration(1)
            setLoading2(false);
            setButtonDisabled(false);
            console.log(res2)
            if (res2.data && res2.data.pStartDate) {
                const tableData = [];
                for (let i = 0; i < res2.data.pStartDate.length; i++) {
                    tableData.push({
                    pStartDate: res2.data.pStartDate[i],
                    pLor: res2.data.pLor[i],
                    pStrategy: res2.data.pStrategy[i],
                    pSippCode: res2.data.pSippCode[i],
                    pCompSippCode: res2.data.pCompSippCode[i],
                    pMinimumRate: res2.data.pMinimumRate[i],
                    pCompetitorName: res2.data.pCompetitorName[i]
                    });
                }
                Swal.fire({
                  position: "middle",
                  icon: "success",
                  title: "Automation Completed Successfully",
                  showConfirmButton: false,
                  showCloseButton:true,
                  html: `
                    <div style="height: 360px;">
                        <table border="1" style="border-collapse: collapse;">
                        <tr>
                            <th style="border: 1px solid #000;width: 120px;">Start Date</th>
                            <th style="border: 1px solid #000;width: 70px;">Lor</th>
                            <th style="border: 1px solid #000;width: 100px;">Routes Sipp Code</th>
                            <th style="border: 1px solid #000;width: 120px;">Competitor Name</th>
                            <th style="border: 1px solid #000;width: 120px;">Competitor Sipp Code</th>
                            <th style="border: 1px solid #000;width: 100px;">Strategy</th>                          
                            <th style="border: 1px solid #000;width: 150px;">Is from Minimum Rate</th>
                        </tr>
                        ${tableData.map((rowData) => `
                            <tr>
                            <td style="border: 1px solid #000;">${rowData.pStartDate}</td>
                            <td style="border: 1px solid #000;">${rowData.pLor}</td>
                            <td style="border: 1px solid #000;">${rowData.pSippCode}</td>
                            <td style="border: 1px solid #000;">${rowData.pCompetitorName}</td>
                            <td style="border: 1px solid #000;">${rowData.pCompSippCode}</td>
                            <td style="border: 1px solid #000;">${rowData.pStrategy}</td>
                            <td style="border: 1px solid #000;">${rowData.pMinimumRate}</td>
                            
                            </tr>
                        `).join('')}
                        </table>
                    </div>
                    `,
                });
            }
            else {
                Swal.fire({
                    position: "middle",
                    icon: "error",
                    title: res2.data,
                    showConfirmButton: false,
                    timer: 8000,
                  });
            }
        //   });
    };

    useEffect(() => {
        setLgShow1(true);
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            setRateCodeSelectionAccess("Yes");
            setExcelUploadAccess("Yes");
            setDaysApplicableAccess("Yes");
            setCopyRateAccess("Yes");
        }
        else {
            setRateCodeSelectionAccess(foundUser.access['RateUpdate_Rate Code Selection']);
            setExcelUploadAccess(foundUser.access['RateUpdate_Excel Upload']);
            setDaysApplicableAccess(foundUser.access['RateUpdate_Days Applicable Selection']);
            setCopyRateAccess(foundUser.access['RateUpdate_Copy Rates']);
        }
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateUpdate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }

        fetch(baseURL + "/get_all_rate_codes_names")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            setLocationEnabledForAutomation('No')
            setListOfRateCodes({})
            setRateCodesSelected('')
            setTable([])
            setSelectAllDaysFlag(false)
            resetAllDaysCheckBox()
            setLocationNameForCopyRate('')
            setLocationIDforCopyRate('')
            setPreloadFlag(true);
            setWeekendRatesShowFlag(false);
            setReverseCalculatorGrid({})
            setReverseCalculatorErrorMessage('')

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });

            fetch(baseURL + `/get_remaining_rate_code/${temp_company_id.toString()}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let hmap = {}
                        let arr = []
                        for (let i = 0; i < data.length; i++) {
                            let key = data[i]
                            arr.push(key)
                            hmap[key] = false
                        }
                        setListOfRateCodes(hmap)

                        let res = []
                        let size = 2
                        for (let i = 0; i < arr.length; i += size) {
                            let temp = []
                            for (let j = i; j < i + size; j++) {
                                if (arr[j] !== undefined) {
                                    temp.push(arr[j])
                                }
                                else {
                                    temp.push(null)
                                }
                            }
                            res.push(temp)
                        }
                        setTable(res)
                    }
                })

            setCarDetails([])
            // setCarDetailsInOption1([])
            setCarDetailsInOptionConstant([])
            setGridDetails({})
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            setSelectAllDaysFlag(false)
            setRateCodesSelected('')
            setLocationNameForCopyRate('')
            setLocationIDforCopyRate('')
            setRateCodeForCopyRate('')
            resetAllDaysCheckBox();
            resetSelectedRateCodes();
            setFromDate(new Date());
            setToDate(new Date());
            setInstantFromDate(new Date());
            setInstantToDate(new Date());
            setPreloadFlag(true);
            setReverseCalculatorErrorMessage('')

            setSelectedStrategy([]);
            setSelectedSippValues([]);
            setSelectedSippValues2([]);
            setSelectedSippValues3([]);
            setSelectedCompetitor([]);
            setSelectedChange([]);
            setSelectedAmount([]);
            // setSelectedCompetitionOption('');
            setNumbers({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false});
            //setListOfRateCodes({});
            setSelectedPickUpTime('');
            setSelectedApplicableDays('');

            axios
            .get(baseURL + "/get_automation_enabled_info/" + locationName)
            .then((res) => {
                const automationEnabled = res.data[0]['enabled'] ;
                if (automationEnabled === true){
                    setLocationEnabledForAutomation("Yes")
                }else{
                    setLocationEnabledForAutomation("No")
                }
            });

            fetch(baseURL + "/get_car_classes/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setCarDetails(data);
                        setCarDetailsInOption1(data)
                        setCarDetailsInOptionConstant(data)
                        let hmap = {}
                        for (let i = 0; i < data.length; i++) {
                            hmap[data[i][0] + '_1Day'] = ''
                            hmap[data[i][0] + '_2Day'] = ''
                            hmap[data[i][0] + '_3Day'] = ''
                            hmap[data[i][0] + '_4Day'] = ''
                            hmap[data[i][0] + '_5Day'] = ''
                            hmap[data[i][0] + '_6Day'] = ''
                            hmap[data[i][0] + '_DailyXDay'] = ''
                            hmap[data[i][0] + '_Weekly'] = ''
                            hmap[data[i][0] + '_WeeklyXDay'] = ''
                            hmap[data[i][0] + '_Monthly'] = ''
                            hmap[data[i][0] + '_MonthlyXDay'] = ''
                            hmap[data[i][0] + '_Weekend'] = ''
                            hmap[data[i][0] + '_Hourly'] = ''
                            hmap[data[i][0] + '_Minute'] = ''
                            hmap[data[i][0] + '_PerKmMile'] = ''
                        }
                        setGridDetails(hmap)
                    }
                    else {
                        setCarDetails([])
                        setCarDetailsInOption1([])
                        setCarDetailsInOptionConstant([])
                        setGridDetails({})
                    }
                });
            
            fetch(baseURL + "/get_fleet_standard_car_details/" + company_id)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    setFleetStandardCarDetails(data);
                    setCarDetailsInOption2(data)
                }
            })

            axios
                .get(baseURL + "/get_all_competitor_setup_info/" + locationName)
                .then(response => {
                const newArray = response.data.selected_items.map(subArray => subArray[0]);
                setCompetitorsList(newArray)
                })
                .catch(error => {
                console.error("Second Axios Request Error:", error);
                });

            axios
            .get(baseURL + "/get_automation_instant_info/" + locationName)
            .then((res) => {
                const formattedStrategy2 = {};
                const formattedCompSipp2 = {};
                const formattedCompSipp3 = {};
                const formattedCompSipp4 = {};
                const formattedCompetitor2 = {};
                const formattedChange2 = {};
                const formattedAmount2 = {};
                // setSelectedCompetitionOption(res.data.competitionSelected)
                if(res.data.competitionSelected === undefined){
                    setSelectedCompetitionOption("SIPP CODE")
                }else{
                    setSelectedCompetitionOption(res.data.competitionSelected)
                }

                if(res.data.paritySelected === undefined){
                    setSelectedRateParity("Static")
                }else{
                    setSelectedRateParity(res.data.paritySelected)
                }

                if(res.data.rateSourceSelected === undefined){
                    setSelectedRateSourceOption("Expedia")
                    setCarDetailsInOption1(expediaOptions)
                    setCarDetailsInOption2(expediaOptions)
                }else{
                    setSelectedRateSourceOption(res.data.rateSourceSelected)
                }

                if(res.data.competitionSelected === "Car Category" && res.data.rateSourceSelected == 'Expedia' ){
                    setCarDetailsInOption1(expediaOptions)
                    setCarDetailsInOption2(expediaOptions)
                }else if(res.data.competitionSelected === "Car Category" && res.data.rateSourceSelected == 'Priceline'){
                    setCarDetailsInOption1(pricelineOptions)
                    setCarDetailsInOption2(pricelineOptions)
                }
        
                res.data.strategy.forEach((item, index) => {
                    formattedStrategy2[index] = { strategyValues: item };
                });
                res.data.comp_sipp_code.forEach((item, index) => {
                    formattedCompSipp2[index] = { compSippValues: item };
                });
                if (res.data.comp_sipp_code2 && res.data.comp_sipp_code2.length > 0) {
                    res.data.comp_sipp_code2.forEach((item, index) => {
                      formattedCompSipp3[index] = { compSippValues2: item };
                    });
                    const formattedCompSipp2f = Object.keys(formattedCompSipp3).map(key => formattedCompSipp3[key]);
                    setSelectedSippValues2(formattedCompSipp2f);
                }
                if (res.data.comp_sipp_code3 && res.data.comp_sipp_code3.length > 0) {
                    res.data.comp_sipp_code3.forEach((item, index) => {
                        formattedCompSipp4[index] = { compSippValues3: item };
                    });
                    const formattedCompSipp3f = Object.keys(formattedCompSipp4).map(key => formattedCompSipp4[key]);
                    setSelectedSippValues3(formattedCompSipp3f);
                }     
                
                res.data.selected_competitor.forEach((item, index) => {
                    formattedCompetitor2[index] = { competitorValues: item };
                });
                res.data.selected_change.forEach((item, index) => {
                    formattedChange2[index] = { changeValues: item };
                });
                res.data.amount.forEach((item, index) => {
                    formattedAmount2[index] = { amountValues: item };
                });
                const formattedCompSipp = Object.keys(formattedCompSipp2).map(key => formattedCompSipp2[key]);
                const formattedStrategy = Object.keys(formattedStrategy2).map(key => formattedStrategy2[key]);
                const formattedCompetitor = Object.keys(formattedCompetitor2).map(key => formattedCompetitor2[key]);
                const formattedChange = Object.keys(formattedChange2).map(key => formattedChange2[key]);
                const formattedAmount = Object.keys(formattedAmount2).map(key => formattedAmount2[key]);
               
                setSelectedStrategy(formattedStrategy);
                setSelectedSippValues(formattedCompSipp);
                setSelectedCompetitor(formattedCompetitor);
                setSelectedChange(formattedChange);
                setSelectedAmount(formattedAmount);
                setInstantFromDate(res.data.from_date);
                setSelectedPickUpTime(res.data.pickup_time);
                setSelectedAdvanceDays(res.data.advance_days);
                setSelectedApplicableDays(res.data.applicable_days);
                setInstantToDate(res.data.to_date);
                setNumbers(res.data.lor);
            });

            let temp = {}
            for (let i = 1; i <= 7; i++) {
                temp[i] = { "price_inclusive_of_taxes": '', "price_to_update": '' };
            }
            setReverseCalculatorGrid(temp)

            fetch(baseURL + "/get_location_details/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    let status = data['item']['weekend_rates_to_be_shown']
                    setWeekendRatesShowFlag(!status)
                })
        }
    }, [locationName])
 
    useEffect(() => {
    if(selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Expedia'){
        setCarDetailsInOption1(expediaOptions)
        setCarDetailsInOption2(expediaOptions)
    }else if(selectedCompetitionOption == "Car Category"  && selectedRateSourceOption == 'Priceline'){
        setCarDetailsInOption1(pricelineOptions)
        setCarDetailsInOption2(pricelineOptions)
    }else{
        setCarDetailsInOption1(carDetailsInOptionConstant)
        setCarDetailsInOption2(fleetStandardCarDetails)
    }
    }, [carDetailsInOption1, selectedCompetitionOption]);  

    // useEffect(() => {
    //     previewRatesInGridInModal();
    // }, [selectedDatePicker])

    // useEffect(() => {
    //     previewRatesInModal();
    // }, [locationNameForCopyRate, rateCodeForCopyRate])

    useEffect(() => {
        if (company_id === '' || locationId === '' || Object.keys(listOfRateCodes).filter((k) => (listOfRateCodes[k])).length < 1) {
            return
        }
        else {
            fetch(baseURL + "/get_rate_update_data_for_download_excel_with_rates", {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "from_date": new Date(fromDate).toDateString(),
                    "to_date": new Date(toDate).toDateString(),
                    "rate_codes": Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key]))
                })
            })
                .then(resp => resp.json())
                .then((data) => {
                    setRatesForExcelDownloadWithRates(data);
                })
        }
    }, [company_id, locationId, listOfRateCodes, fromDate, toDate])


    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const rateCodeOptionsForCopyRates = Object.keys(listOfRateCodes).map((val) => (
        { value: val, label: rateCodeNames[val] }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    const handleInputChangeRateCodeForCopyRate = characterEntered => {
        setRateCodeForCopyRate(characterEntered.value);
        setRateCodeForCopyRateCodeID();
    };

    const changeAllDaysCheckBox = () => {
        let temp = { ...daysNameHash }
        if (selectAllDaysFlag === true) {
            setSelectAllDaysFlag(false)
            let keys = Object.keys(temp)
            for (let i = 0; i < keys.length; i++) {
                temp[keys[i]] = false
            }
        }
        else {
            setSelectAllDaysFlag(true)
            let keys = Object.keys(temp)
            for (let i = 0; i < keys.length; i++) {
                temp[keys[i]] = true
            }
        }
        setDaysNameHash(temp)
        // setPreloadFlag(true)
    }

    const resetSelectedRateCodes = () => {
        let temp = { ...listOfRateCodes }
        let keys = Object.keys(temp)
        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = false
        }
        setListOfRateCodes(temp)
        setPreloadFlag(true);
    }

    const changeDaysCheckBox = (key) => {
        let temp = { ...daysNameHash };
        let status = temp[key]
        temp[key] = !status

        setDaysNameHash(temp);
    }

    const changeCheckBox = (key) => {
        let temp = { ...listOfRateCodes };
        let status = temp[key]
        temp[key] = !status

        let keys = Object.keys(temp);

        for (let i = 0; i < keys.length; i++) {
            if (keys[i] !== key) {
                temp[keys[i]] = false;
            }
        }

        setListOfRateCodes(temp);

        // disable preload button once no rate codes are selected
        let selectedRateCodes = 0
        let allSelectedRateCodeNames = []

        for (let i = 0; i < keys.length; i++) {
            if (temp[keys[i]]) {
                selectedRateCodes += 1;
                allSelectedRateCodeNames.push(rateCodeNames[keys[i]])
            }
        }

        setRateCodesSelected(allSelectedRateCodeNames.toString())

        if (selectedRateCodes >= 1) {
            setPreloadFlag(false);
        }
        else {
            setPreloadFlag(true);
        }
    }

    const resetAllDaysCheckBox = () => {
        let temp = { ...daysNameHash }

        setSelectAllDaysFlag(true)

        let keys = Object.keys(temp)

        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = true
        }

        setDaysNameHash(temp)
    }

    const changeGridValues = (key, val) => {
        if ((isNaN(val) && val !== '.') || val.toString().indexOf("-") > -1) {
            Swal.fire('Only numbers allowed')
        }
        else {
            let hmap = { ...gridDetails }

            hmap[key] = val

            setGridDetails(hmap)
        }
    }

    const changeGridValuesInModal = (key, val, dateKey) => {
        if ((isNaN(val) && val !== '.') || val.toString().indexOf("-") > -1) {
            Swal.fire('Only numbers allowed')
        }
        else {
            let hmap = { ...gridDetailsInModal }
            hmap[key] = val
            setGridDetailsInModal(hmap)
            let details = { ...gridDetailsInModalForDateRange }
            details[formatDate_Modal(new Date(dateKey))] = hmap;
            setGridDetailsInModalForDateRange(details);
        }
    }

    const resetGridValues = () => {
        let hmap = { ...gridDetails }

        let keys = Object.keys(hmap)
        for (let i = 0; i < keys.length; i++) {
            hmap[keys[i]] = ''
        }

        setGridDetails(hmap)
    }

    const resetGridValuesInModal = () => {
        let hmap = { ...gridDetailsInModal }

        let keys = Object.keys(hmap)
        for (let i = 0; i < keys.length; i++) {
            hmap[keys[i]] = ''
        }

        setGridDetailsInModal(hmap)
        setCopyRateFlag(true)
    }

    const getRateUpdateGrid = () => {
        resetGridValues();

        let rateCodeKeys = Object.keys({ ...listOfRateCodes })
        let selectedRateCode = ''

        for (let i = 0; i < rateCodeKeys.length; i++) {
            if (listOfRateCodes[rateCodeKeys[i]]) {
                selectedRateCode = rateCodeKeys[i]
                break;
            }
        }

        if (selectedRateCode !== '') {
            fetch(baseURL + "/get_rate_update_data", {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "date": new Date(fromDate).toDateString(),
                    "rate_code": selectedRateCode
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        let hmap = data['car_class_rates']
                        let temp = { ...gridDetails }
                        let keys = Object.keys(temp)

                        for (let i = 0; i < keys.length; i++) {
                            let key = keys[i]

                            temp[key] = hmap[key] === undefined ? '' : hmap[key]
                        }

                        setGridDetails(temp)
                    }
                })
        }
    }

    const openModal = () => {

        // setLocationNameForCopyRate('')
        // setLocationIDforCopyRate('')
        // setRateCodeForCopyRate('')
        // setSelectedDatePicker('')

        previewRatesInModal();

        // setCarDetailsInModal([])
        // setGridDetailsInModal({})

        // setGridDetailsInModalForDateRange({})

        // createDatesArr(fromDate, toDate);

        setLgShow(true);
    }

    const handleInputChangeLocationNameForCopyRate = characterEntered => {
        setLocationNameForCopyRate(characterEntered.value);
        // resetGridValuesInModal();
    };

    const handleInputChangeDatePicker = characterEntered => {
        setSelectedDatePicker(characterEntered.value);
        // resetGridValuesInModal();
    };

    const submitData = () => {
        Swal.fire({
            title: 'Please note that the rates entered are Total Rates that are inclusive of all fees and taxes.',
            showCancelButton: true,
            confirmButtonText: 'OK',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setSuccessMsg("");
                    setLoading(true);
                    setValidationMsg("");

                    if (company_id === '') {
                        setLoading(false);
                        setValidationMsg('Please select Account Name to proceed...');
                        window.scrollTo(0, 0);
                        return
                    }

                    if (locationId === '') {
                        setLoading(false)
                        setValidationMsg('Please select Location Name to proceed...')
                        window.scrollTo(0, 0);
                        return
                    }

                    // get selected rate codes
                    let rateCodeKeys = Object.keys({ ...listOfRateCodes })
                    let rateCodeFlag = false
                    let selectedRateCode = []

                    for (let i = 0; i < rateCodeKeys.length; i++) {
                        if (listOfRateCodes[rateCodeKeys[i]]) {
                            rateCodeFlag = true
                            selectedRateCode.push(rateCodeKeys[i])
                        }
                    }

                    // get selected days
                    let daysKeys = Object.keys({ ...daysNameHash })
                    let daysFlag = false
                    let selectedDays = []

                    for (let i = 0; i < daysKeys.length; i++) {
                        if (daysNameHash[daysKeys[i]]) {
                            daysFlag = true
                            selectedDays.push(daysKeys[i])
                        }
                    }

                    if (rateCodeFlag === false) {
                        setLoading(false)
                        setValidationMsg('Please select Rate Code(s) to proceed...')
                        window.scrollTo(0, 0);
                    }
                    else if (daysFlag === false) {
                        setLoading(false)
                        setValidationMsg('Please select Day(s) to proceed...')
                        window.scrollTo(0, 0);
                    }
                    else {
                        let hmap = { ...gridDetails }
                        let finalHmap = {}
                        let keys = Object.keys(hmap)

                        for (let i = 0; i < keys.length; i++) {
                            let key = keys[i]
                            let val = hmap[key]

                            if (val === '.') {
                                finalHmap[key] = ''
                            }
                            else if (val !== '') {
                                finalHmap[key] = parseFloat(val)
                            }
                            else {
                                finalHmap[key] = ''
                            }
                        }

                        let requestBody = {
                            // "account_name": companyName.toString(),
                            "account_id": company_id.toString(),
                            // "location_name": locationName.toString(),
                            "location_id": locationId.toString(),
                            "from_date": new Date(fromDate).toDateString(),
                            "to_date": new Date(toDate).toDateString(),
                            "selected_rate_codes": selectedRateCode,
                            "selected_days": selectedDays,
                            "type_of_upload": "M",
                            "last_edited_by": loggedInUserDetail.userName,
                            "last_edited_date": new Date(),
                            "rate_details": finalHmap
                        }

                        fetch(baseURL + '/save_rates', {
                            method: 'POST', headers: { 'Content-type': 'application/json' },
                            body: JSON.stringify(requestBody)
                        })
                            .then(resp => resp.json())
                            .then(data => {
                                setLoading(false)
                                if (data === 'Success') {
                                    setSuccessMsg("Rate(s) updated successfully!")
                                    window.scrollTo(0, 0);
                                    resetGridValues()
                                    setWeekendRatesShowFlag(false)
                                    // resetAllDaysCheckBox()
                                }
                                else {
                                    setValidationMsg('Error saving Rate Update Data. Please try again!')
                                    window.scrollTo(0, 0);
                                }
                            })
                            .catch(error => console.log(error))
                    }
                }
            })
    }

    const submitDataInModal = () => {
        Swal.fire({
            title: 'Are you sure, you want to copy the Rate Code(s)?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // get selected rate codes
                    let rateCodeKeys = Object.keys({ ...listOfRateCodes })
                    let rateCodeFlag = false
                    let selectedRateCode = []

                    // for (let i = 0; i < rateCodeKeys.length; i++) {
                    //     if (listOfRateCodes[rateCodeKeys[i]]) {
                    //         rateCodeFlag = true
                    //         selectedRateCode.push(rateCodeKeys[i])
                    //     }
                    // }
                    let temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationNameForCopyRate)).map((val) => val.location_id)

                    // return
                    // console.log("--gridDetailsInModalForDateRange--",gridDetailsInModalForDateRange);
                    // console.log("--hash--",checkBoxSelctedToCopy);
                    // return;
                    // console.log("--submit_copy--",gridDetailsInModalForDateRange)

                    let requestBody = {
                        "account_name": companyName.toString(),
                        "account_id": company_id.toString(),
                        "location_name": locationNameForCopyRate.toString(),
                        "location_id": temp_location_id.toString(),
                        "selected_rate_codes": rateCodeForCopyRate,
                        "from_date": new Date(copyToDate).toDateString(),
                        "to_date": new Date(copyToDate).toDateString(),
                        // "location_name_to_copy_from": locationNameForCopyRate,
                        // "location_id_to_copy_from": locationIDforCopyRate.toString(),
                        // "rate_code_to_copy_from": rateCodeForCopyRate,
                        "car_class_rate_details": gridDetailsInModalForDateRange,
                        "type_of_upload": "C",
                        "last_edited_by": loggedInUserDetail.userName,
                        "last_edited_date": new Date(),
                        "checkBoxSelctedToCopy": checkBoxSelctedToCopy
                    }

                    fetch(baseURL + '/copy_rates', {
                        method: 'POST', headers: { 'Content-type': 'application/json' },
                        body: JSON.stringify(requestBody)
                    })
                        .then(resp => resp.json())
                        .then(data => {
                            if (data === 'Success') {
                                Swal.fire({
                                    title: 'Rate(s) copied successfully!'
                                })
                                resetGridValuesInModal()
                                setGridDetailsInModalForDateRange({})
                                setLgShow(false)
                            }
                            else {
                                Swal.fire({
                                    title: 'Error copying Rate(s). Please try again!'
                                })
                            }
                        })
                        .catch(error => console.log(error))
                }
            })
    }

    const createDatesArr = (start, end) => {
        if (start !== '' && end !== '') {
            let res = []

            let startDate = new Date(start)
            let endDate = new Date(end)

            for (let i = startDate.valueOf(); i <= endDate.valueOf(); i += 1000 * 24 * 60 * 60) {
                res.push(i)
            }

            setDateArr(res);
        }
    }

    const datePickerOptions = dateArr.map((val) => (
        { value: val, label: formatDate(new Date(val)) }
    ));

    const previewRatesInGridInModal = () => {
        if (locationNameForCopyRate === '' || rateCodeForCopyRate === '' || selectedDatePicker === '') {
            return;
        }
        setCopyRateFlag(false);
        let tempHash = { ...gridDetailsInModalForDateRange };
        let temp_date = formatDate_Modal(new Date(selectedDatePicker))
        if (tempHash[temp_date]) {
            setGridDetailsInModal(tempHash[temp_date])
        }
    }

    const previewRatesInModal = () => {
        // if (locationNameForCopyRate === '' || rateCodeForCopyRate === '') {
        //     console.log("--cp0--")
        //     return
        // }
        console.log("--cp0--")
        console.log("--rateCodeForCopyRate--", rateCodeForCopyRate)
        setCopyRateFlag(false)
        // setSelectedDatePicker('');
        // resetGridValuesInModal();
        let temp_location_id = ''
        let temp_company_id = company_id.toString();
        let rateCodeKeys = Object.keys({ ...listOfRateCodes })
        let selectedRateCode = ''
        console.log("--cp0--002")
        for (let i = 0; i < rateCodeKeys.length; i++) {
            if (listOfRateCodes[rateCodeKeys[i]]) {
                selectedRateCode = rateCodeKeys[i]
                break;
            }
        }
        console.log("--cp0001--")
        if (locationDetail) {
            console.log("--cp01--")
            // temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            // setLocationIDforCopyRate(locationId.toString())
            console.log("--cp02--")
            fetch(baseURL + "/get_car_classes/" + locationId.toString())
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let finalData = carDetails;
                        let hash_selected_checkbox = {}
                        for (let i = 0; i < finalData.length; i++) {
                            hash_selected_checkbox[finalData[i][0]] = true
                        }
                        setCheckBoxSelctedToCopy(hash_selected_checkbox)
                        setCarDetailsInModal(finalData);
                        // let selectedRateCode = rateCodeForCopyRate
                        fetch(baseURL + "/get_rate_update_data_for_copy_rates", {
                            method: 'POST', headers: { 'Content-type': 'application/json' },
                            body: JSON.stringify({
                                "account_id": temp_company_id.toString(),
                                "location_id": locationId.toString(),
                                "rate_code": selectedRateCode,
                                "from_date": new Date(fromDate).toDateString(),
                                "to_date": new Date(fromDate).toDateString()
                            })
                        })
                            .then(resp => resp.json())
                            .then((data1) => {
                                console.log("--cp2--")
                                if (data1 && Object.keys(data1).length > 0) {
                                    let dateKeys = Object.keys(data1)
                                    let gridDetailsForDateRange = {}
                                    for (let k = 0; k < dateKeys.length; k++) {
                                        let dateKey = dateKeys[k]
                                        let hmap = {}
                                        for (let i = 0; i < finalData.length; i++) {
                                            hmap[finalData[i][0] + '_1Day'] = ''
                                            hmap[finalData[i][0] + '_2Day'] = ''
                                            hmap[finalData[i][0] + '_3Day'] = ''
                                            hmap[finalData[i][0] + '_4Day'] = ''
                                            hmap[finalData[i][0] + '_5Day'] = ''
                                            hmap[finalData[i][0] + '_6Day'] = ''
                                            hmap[finalData[i][0] + '_DailyXDay'] = ''
                                            hmap[finalData[i][0] + '_Weekly'] = ''
                                            hmap[finalData[i][0] + '_WeeklyXDay'] = ''
                                            hmap[finalData[i][0] + '_Monthly'] = ''
                                            hmap[finalData[i][0] + '_MonthlyXDay'] = ''
                                            hmap[finalData[i][0] + '_Weekend'] = ''
                                            hmap[finalData[i][0] + '_Hourly'] = ''
                                            hmap[finalData[i][0] + '_Minute'] = ''
                                            hmap[finalData[i][0] + '_PerKmMile'] = ''
                                        }
                                        let hmap1 = data1[dateKey]
                                        let keys = Object.keys(hmap)
                                        for (let j = 0; j < keys.length; j++) {
                                            let key = keys[j]
                                            hmap[key] = hmap1[key] === undefined ? '' : hmap1[key]
                                        }
                                        gridDetailsForDateRange[dateKey] = hmap
                                    }
                                    console.log("--gridDetailsForDateRange--", gridDetailsForDateRange)
                                    setGridDetailsInModalForDateRange(gridDetailsForDateRange)

                                    let temp_date = formatDate_Modal(new Date(fromDate))
                                    console.log("--temp_date--", temp_date)
                                    // if (tempHash[temp_date]) {
                                    setCopyRateFlag(false);
                                    setGridDetailsInModal(gridDetailsForDateRange[temp_date])
                                    console.log("--gridDetailsForDateRange[temp_date]--", gridDetailsForDateRange[temp_date])
                                    // }
                                }
                                else {
                                    setGridDetailsInModalForDateRange({})
                                }
                            })
                    }
                    else {
                        setCarDetailsInModal([])
                        setGridDetailsInModalForDateRange({})
                    }
                });
        }
    }

    const changeForwardCalculatorGridValues = (val, key) => {
        let temp = { ...reverseCalculatorGrid }
        temp[key]['price_non_inclusive_of_taxes'] = val
        axios.post(baseURL + "/get_forward_calculated_value",
        {
            'location_id': locationId.toString(),
            "price": val,
            "lor": key
        })
        .then((res) => {
            if (res.data == "Error") {
                temp[key]['price_to_update'] = ""
                setReverseCalculatorGrid(temp)
            }else{
                let new_val = res.data['new_price'];
                temp[key]['price_to_update'] = new_val.toString();
                setReverseCalculatorGrid(temp)
            }
        })
    }
    const handleCheckboxSelectedCopyRate = (car_class) => {
        const new_temp = { ...checkBoxSelctedToCopy };
        new_temp[car_class] = !new_temp[car_class]
        setCheckBoxSelctedToCopy(new_temp);
    }

    return (
        <>
        <Modal
            size="md"
            show={lgShow1}
            onHide={() => setLgShow1(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-60w"
            centered
            >
        <Modal.Header closeButton style={{ backgroundColor: '#a7bceb', color: 'black' }} >
          <Modal.Title id="example-modal-sizes-title-lg">
            Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <span style={{fontSize:'15px'}}>Please note that the rates in the rate update table are now to be entered as Total Rate which is inclusive of all fees and taxes.</span>
        </Modal.Body>
      </Modal>
            <Modal
                size="lg"
                show={lgShow5}
                onHide={() => setLgShow5(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                        Rate Automation - Instant
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form >
                        {/* <div className="row pt-3"> */}
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Account Name</b>
                                </label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={companyNameOptions}
                                    isDisabled={true} 
                                    onChange={handleInputChangeCompanyName}
                                />
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Location Name</b>
                                </label>
                            </div>
                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                    inputId="locationName"
                                    name="locationName"
                                    options={locationNameOptions}
                                    isDisabled={true} 
                                    onChange={handleInputChangeLocationName}
                                />
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    <b>PickUp Time</b>
                                </label>
                            </div>
                            <div className="col-md-2 mb-3" style={{marginLeft:'0px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td>
                                        <select style={{textAlign: "center"}}
                                            value={selectedPickUpTime}
                                            onChange={(e) => handlePickUpTime(e.target.value)}
                                            >
                                            <option value="">Select Time</option>
                                            {hours.map((val) => (
                                                <option value={val.name} key={val.name}>
                                                {val.name}
                                                </option>
                                            ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                            <b>From Date</b>
                                        </label>
                                    </div>
                                    <div className="site-calendar-demo-card col-md-4 mb-3" style={{ marginLeft: '29px' }}>
                                        <Calendar
                                            fullscreen={false}
                                            value={moment(instantFromDate.valueOf())}
                                            onChange={(value) => {
                                                setInstantFromDate(value._d);
                                                setInstantToDate(value._d);
                                            }}
                                            disabledDate={(current) => {
                                                if (instantToDate && instantFromDate.valueOf() !== instantToDate.valueOf()) {
                                                    return current && (current.valueOf() > (instantToDate.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                                }
                                                else {
                                                    return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-1 mb-3"></div>
                                    <div className="col-md-1 mb-3">
                                        <label htmlFor="toDate" className="form-label" style={{ marginTop: 5 }}>
                                            <b>To Date</b>
                                        </label>
                                    </div>
                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar
                                            fullscreen={false}
                                            value={moment(instantToDate.valueOf())}
                                            onChange={(value) => { setInstantToDate(value._d) }}
                                            disabledDate={(current) => {
                                                if (instantFromDate) {
                                                    const startDate = moment(instantFromDate);
                                                    if (loggedInUserDetail.userEmail === 'kjs@routes.ca') {
                                                        const endDate = moment(instantFromDate).add(20, 'days'); // Allow selection within 21 days of start date
                                                        return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                                                    }else{
                                                        const endDate = moment(instantFromDate).add(6, 'days');// Allow selection within 7 days of start date
                                                        return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                                                    }
                                                }
                                                else {
                                                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60));
                                                }
                                            }} />
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Lor's </b>
                                </label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                            <td><input type='checkbox' className='form-check-input' checked={Object.values(numbers).every((value) => value)}
                                                onChange={handleSelectAll} /></td>
                                            <td>Select All</td>

                                            {Object.entries(numbers).map(([number, selected]) => (
                                                <React.Fragment key={number}>

                                                    <td><input type='checkbox' className='form-check-input' checked={selected}
                                                        onChange={() => handleNumberSelection(number)} /></td>
                                                    <td>{number}</td>
                                                    <td>&nbsp;&nbsp;</td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Rate Source</b>
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td>
                                        <select style={{width: "75%", textAlign: "center"}}
                                            value={selectedRateSourceOption}
                                            onChange={(e) => handleRateSourceOption(e.target.value)}
                                            >
                                                {/* options={options3} */}
                                            {/* <option value="">Select Time</option> */}
                                            {rateSourceOptions.map((val) => (
                                                <option value={val.value} key={val.value}>
                                                {val.value}
                                                </option>
                                            ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Competition By</b>
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td>
                                        
                                        <select style={{width: "75%", textAlign: "center"}}
                                            value={selectedCompetitionOption}
                                            onChange={(e) => handleCompetitionOption(e.target.value)}
                                            >
                                            {competitionOptions.map((val) => (
                                                <option value={val.value} key={val.value}>
                                                {val.value}
                                                </option>
                                            ))}
                                                {/* options={options3} */}
                                            {/* <option value="">Select Time</option> */}
                                            {/* { selectedRateSourceOption === 'Expedia' ?
                                                competitionOptions.map((val) => (
                                                    <option value={val.value} key={val.value}>
                                                    {val.value}
                                                    </option>
                                                )) 
                                            :
                                               competitionOptions.map((val) => (
                                                val.label === "SIPP CODE" && (
                                                    <option value={val.value} key={val.value}>
                                                    {val.value}
                                                    </option>
                                                )
                                                ))
                                            } */}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                    <b>Range Value Multiplier</b>
                                </label>
                            </div>
                            <div className="col-md-2 mb-3 text-center"  style={{marginLeft:'0px'}}>
                                <table className="table table-borderless text-left">
                                    <tbody className="text-left">
                                        <tr>
                                        <td>
                                            <select style={{width: "75%", textAlign: "center"}}
                                                value={selectedRateParity}
                                                onChange={(e) => handleParity(e.target.value)}
                                                >
                                                {parityOptions.map((val) => (
                                                    <option value={val.value} key={val.value}>
                                                    {val.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row pt-4 mx-2">
                            <div className="col-md-12" style={{float: "left",overflow:"auto"}}>
                                <table className="table table-bordered table-condensed  text-center">
                                    <thead>
                                        <tr>
                                            <th colSpan="1" style={{width:'6%'}}>SIPP CODES</th>
                                            <th colSpan="3" style={{width:'24%'}}>Competing SIPP CODE Category</th>
                                            <th colSpan="1" style={{width:'10%'}}>Strategy</th>
                                            <th colSpan="1" style={{width: '7% !important' }}>Competitor</th>
                                            <th colSpan="1" style={{width:'10%'}}>Dollar/Percentage</th>
                                            <th colSpan="1" style={{width:'10%'}}>Range Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {carDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{width:'6%'}} rowSpan="1">{item[0]}</td>
                                                <td style={{width:'8%'}} rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues[index]?.compSippValues || ''}
                                                        onChange={(e) => handleComptitorSippCodes(index, e.target.value)}
                                                    >
                                                        {
                                                            selectedCompetitionOption === "Car Category" ?
                                                            <option value="">Select a Category</option>
                                                            :
                                                            <option value="">Select a SIPP</option>
                                                        }

                                                        
                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category" ?    
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option[0]}>
                                                                            {option[0]}
                                                                        </option>
                                                                    ))
                                                                    
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{width:'8%'}} rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues2[index]?.compSippValues2 || ''}
                                                        onChange={(e) => handleComptitorSippCodes2(index, e.target.value)}
                                                    >
                                                        {
                                                            selectedCompetitionOption === "Car Category" ?
                                                            <option value="">Select a Category</option>
                                                            :
                                                            <option value="">Select a SIPP</option>
                                                        }

                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option[0]}>
                                                                            {option[0]}
                                                                        </option>
                                                                    ))
                                                        }
                                                        {/* {fleetStandardCarDetails.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option[0]}>
                                                                {option[0]}
                                                            </option>
                                                        ))} */}
                                                    </select>
                                                </td>
                                                <td style={{width:'8%'}} rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={sippValues3[index]?.compSippValues3 || ''}
                                                        onChange={(e) => handleComptitorSippCodes3(index, e.target.value)}
                                                    >
                                                        {
                                                            selectedCompetitionOption === "Car Category" ?
                                                            <option value="">Select a Category</option>
                                                            :
                                                            <option value="">Select a SIPP</option>
                                                        }

                                                        {
                                                            selectedRateSourceOption === 'Expedia' &&  selectedCompetitionOption === "Car Category" ?
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))
                                                            : selectedRateSourceOption === 'Priceline' &&  selectedCompetitionOption === "Car Category" ? 
                                                                    carDetailsInOption2.map((option, optionIndex) => (
                                                                            <option key={optionIndex} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))
                                                            :       carDetailsInOption2.map((option, optionIndex) => (
                                                                        <option key={optionIndex} value={option[0]}>
                                                                            {option[0]}
                                                                        </option>
                                                                    ))
                                                        }
                                                        {/* {fleetStandardCarDetails.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option[0]}>
                                                                {option[0]}
                                                            </option>
                                                        ))} */}
                                                    </select>
                                                </td>
                                                <td style={{width:'10%'}} rowSpan="1">
                                                    <select style={{textAlign: "center"}}
                                                        value={selectedStrategy[index]?.strategyValues || ''}
                                                        onChange={(e) => handleStrategy(index, e.target.value)}
                                                    >
                                                        <option value="">Select Startegy</option>
                                                        {mainDropdownOptions.map((option, optionIndex) => (
                                                            <option key={optionIndex} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                {( selectedStrategy[index]?.strategyValues === 'Lowest' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest Off-Airport' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest In-Terminal' ||
                                                   selectedStrategy[index]?.strategyValues === '2nd Lowest Off-Airport' ||
                                                   selectedStrategy[index]?.strategyValues === '3rd Lowest Off-Airport' ||
                                                   selectedStrategy[index]?.strategyValues === '2nd Lowest In-Terminal' ||
                                                   selectedStrategy[index]?.strategyValues === '3rd Lowest In-Terminal' ||
                                                   selectedStrategy[index]?.strategyValues === '2nd Lowest' ||
                                                   selectedStrategy[index]?.strategyValues === '3rd Lowest' ||
                                                   selectedStrategy[index]?.strategyValues === 'Lowest among all the Car Classes')  && (
                                                    <>
                                                       <td style={{width:'10%'}} rowSpan="1">
                                                       </td>
                                                       <td style={{width:'10%'}} rowSpan="1">
                                                            <select style={{textAlign: "center"}}
                                                                value={selectedChange[index]?.changeValues || ''}
                                                                onChange={(e) => handleChange(index, e.target.value)}
                                                            >
                                                                {/* <option value="">Select change</option> */}
                                                                {changeOptions.map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td style={{width:'10%'}} rowSpan="1">
                                                            <RangeSlider
                                                                min="-30"
                                                                max="30"
                                                                step="1"
                                                                value={selectedAmount[index]?.amountValues || '0'}
                                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                                {selectedStrategy[index]?.strategyValues === 'Custom' && (
                                                    <>
                                                        <td style={{width: '76% !important' }} rowSpan="1">
                                                            <select style={{textAlign: "center",width:'95%'}}
                                                                value={selectedCompetitor[index]?.competitorValues || ''}
                                                                onChange={(e) => handleCompetitor(index, e.target.value)}
                                                            >
                                                                <option value="">Select Competitor</option>
                                                                {competitorsList.map((competitor) => (
                                                                    <option key={competitor.id} value={competitor.value}>
                                                                        {competitor.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td style={{width:'10%'}} rowSpan="1">
                                                            <select style={{textAlign: "center"}}
                                                                value={selectedChange[index]?.changeValues || ''}
                                                                onChange={(e) => handleChange(index, e.target.value)}
                                                            >
                                                                {/* <option value="">Select change</option> */}
                                                                {changeOptions.map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td  style={{width:'10%'}}rowSpan="1">
                                                            <RangeSlider
                                                                min="-30"
                                                                max="30"
                                                                step="1"
                                                                value={selectedAmount[index]?.amountValues || '0'}
                                                                onChange={(e) => handleAmount(index, e.target.value)}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className = "row">
                        <div className="col-md-5">
                        {loading2 ? (
                            <div className="overlay">
                                <div className="loading-spinner-container">
                                <ProgressBar duration={timeDuration} />
                                
                                <div className="loading-text">
                                    <p><b>Please wait - Rate Automation is in Progress. Please do not click outside or close this screen in the meantime</b></p>
                                </div>
                            </div>
                            </div>
                            ) 
                            : ("")
                            }
                        </div>
                            <div className="col-md-2">
                                <button style={{width: '150px'}} type="submit" disabled={buttonDisabled}  className="btn btn-success" onClick={handleAutomationSubmit} >Submit </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                >
                <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#888E8E' }}>
                    <Modal.Title id="example-custom-modal-styling-title" >
                        Copy Rate(s)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Please select <strong>Date</strong>, <strong>Location</strong> and <strong>Rate Code</strong> to Preview and/or Copy Rates</h5>

                    <div className="row pt-5">
                        <div className="col-md-1 mb-3">
                            <label htmlFor="datePicker" className="form-label" style={{ marginTop: 5 }}>
                                Location
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <input
                                type="text"
                                className="form-control"
                                value={locationName}
                                id="locationName"
                                disabled
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-1 mb-3">
                            <label htmlFor="datePicker" className="form-label" style={{ marginTop: 5 }}>
                                Rate Code(s)
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <input
                                type="text"
                                className="form-control"
                                value={rateCodesSelected}
                                id="rateCodesSelected"
                                disabled
                            />
                        </div>
                        <div className="col-md-1 mb-3"></div>
                        <div className="col-md-1 mb-3">
                            <label htmlFor="datePicker" className="form-label" style={{ marginTop: 5 }}>
                                Selected Date
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <DatePicker
                                size="large"
                                format="MM-DD-YYYY"
                                placeholder="mm-dd-yyyy"
                                onChange={(date, dateString) => {
                                    setFromDate(date);
                                }}
                                style={{ width: "100%", position: "relative" }}
                                value={fromDate ? moment(new Date(fromDate)) : fromDate}
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                                disabled
                            />

                        </div>
                    </div>


                    <div className="row pt-2">
                        <div className="col-md-1 mb-3">
                            <label htmlFor="copyFromLocation" className="form-label">
                                Copy To Location
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <Select
                                value={{ value: locationNameForCopyRate, label: (locationNameForCopyRate ? locationNameForCopyRate : "Select...") }}
                                inputId="locationNameForCopyRate"
                                name="locationNameForCopyRate"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationNameForCopyRate}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-1 mb-3">
                            <label htmlFor="copyFromRateCodes" className="form-label">
                                Copy To Rate Code
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <Select
                                value={{ value: rateCodeForCopyRate, label: (rateCodeForCopyRate ? rateCodeNames[rateCodeForCopyRate] : "Select...") }}
                                inputId="rateCodeForCopyRates"
                                name="rateCodeForCopyRates"
                                options={rateCodeOptionsForCopyRates}
                                onChange={handleInputChangeRateCodeForCopyRate}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-1 mb-3">
                            <label htmlFor="datePicker" className="form-label" style={{ marginTop: 5 }}>
                                Copy To Date
                            </label>
                        </div>

                        <div className="col-md-2 mb-3">
                            <DatePicker
                                size="large"
                                format="MM-DD-YYYY"
                                placeholder="mm-dd-yyyy"
                                onChange={(date, dateString) => {
                                    setCopyToDate(date);
                                }}
                                style={{ width: "100%", position: "relative" }}
                                value={copyToDate ? moment(new Date(copyToDate)) : copyToDate}
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                }}
                            />
                        </div>
                    </div>


                    {
                        carDetailsInModal.length > 0 ?
                            <>
                                <div className="row pt-4 mx-2">
                                    <div className="col-md-12" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                        <table className="table table-bordered table-condensed  text-center">
                                            <thead>
                                                <tr>
                                                    <th>Select to Copy</th>
                                                    <th>Car Class</th>
                                                    <th>1 Day</th>
                                                    <th>2 Day</th>
                                                    <th>3 Day</th>
                                                    <th>4 Day</th>
                                                    <th>5 Day</th>
                                                    <th>6 Day</th>
                                                    <th>Daily X-Day</th>
                                                    <th>Weekly</th>
                                                    <th>Weekly X-day</th>
                                                    <th>Monthly</th>
                                                    <th>Monthly X-Day</th>
                                                    <th>Weekend</th>
                                                    <th>Hourly</th>
                                                    <th>Minute</th>
                                                    <th>Per Mile/Km</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-left">

                                                {
                                                    carDetailsInModal.map((val) => (

                                                        <tr>
                                                            <td>
                                                                <div class="form-check d-flex justify-content-center">
                                                                    <input className="form-check-input" checked={checkBoxSelctedToCopy[val[0]]} type="checkbox" value="" id="flexCheckDefault" style={{ height: '20px', width: '20px' }} onChange={() => handleCheckboxSelectedCopyRate(val[0])} />
                                                                </div>
                                                            </td>
                                                            <td>{val[0]}</td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_1Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_1Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_2Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_2Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_3Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_3Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_4Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_4Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_5Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_5Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' style={{ width: '80px' }} className="form-control" value={gridDetailsInModal[val[0] + "_6Day"]} onChange={(e) => changeGridValuesInModal(val[0] + "_6Day", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' disabled={companyName === "Routes Puerto Rico" ? false : true} className="form-control" value={gridDetailsInModal[val[0] + "_DailyXDay"]} onChange={(e) => changeGridValuesInModal(val[0] + "_DailyXDay", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' className="form-control" value={gridDetailsInModal[val[0] + "_Weekly"]} onChange={(e) => changeGridValuesInModal(val[0] + "_Weekly", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' className="form-control" value={gridDetailsInModal[val[0] + "_WeeklyXDay"]} onChange={(e) => changeGridValuesInModal(val[0] + "_WeeklyXDay", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' className="form-control" value={gridDetailsInModal[val[0] + "_Monthly"]} onChange={(e) => changeGridValuesInModal(val[0] + "_Monthly", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' className="form-control" value={gridDetailsInModal[val[0] + "_MonthlyXDay"]} onChange={(e) => changeGridValuesInModal(val[0] + "_MonthlyXDay", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' disabled={weekendRatesShowFlag} className="form-control" value={gridDetailsInModal[val[0] + "_Weekend"]} onChange={(e) => changeGridValuesInModal(val[0] + "_Weekend", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' disabled={true} className="form-control" value={gridDetailsInModal[val[0] + "_Hourly"]} onChange={(e) => changeGridValuesInModal(val[0] + "_Hourly", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' disabled={true} className="form-control" value={gridDetailsInModal[val[0] + "_Minute"]} onChange={(e) => changeGridValuesInModal(val[0] + "_Minute", e.target.value, fromDate)} /></td>
                                                            <td><input type='text' disabled={true} className="form-control" value={gridDetailsInModal[val[0] + "_PerKmMile"]} onChange={(e) => changeGridValuesInModal(val[0] + "_PerKmMile", e.target.value, fromDate)} /></td>

                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-10">&nbsp;</div>

                                    <div className="col-2 d-flex justify-content-end mt-3">
                                        <button type="submit" className="btn btn-success" onClick={submitDataInModal}
                                            disabled={copyRateFlag}>
                                            Copy Rate(s)
                                        </button>
                                    </div>
                                </div>
                            </> : ''
                    }
                </Modal.Body>
            </Modal>


            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Location to Update Rate:</span>
                </div>
            </div>


            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        {/* <div className="row pt-3"> */}
                        {
                                locationEnabledForAutomation === 'Yes'?
                                <div className="col-md-3 mb-3">
                                    <button className="btn btn-success" onClick={() => setLgShow5(true)}>
                                        Rate Automation Instant
                                    </button>
                                </div> : ''
                        }
                        
                        {/* </div> */}

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            /> */}
                        </div>
                    </div>


                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Location ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={locationId}
                                id="locationId"
                                disabled
                            /> */}
                        </div>
                    </div>


                    {
                        rateCodeSelectionAccess === 'Yes' && table.length > 0 ?
                            <div>
                                <div className="row pt-4">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="rateCode" className="form-label" style={{ marginTop: 5 }}>
                                            Rate Code
                                        </label>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <table className="table table-borderless text-left">
                                            <tbody className="text-left">
                                                {
                                                    table.map((val) => (
                                                        <tr>
                                                            {val.map((v) => (
                                                                v == null || rateCodeNames[v] == undefined ? '' :
                                                                    <>
                                                                        <td><input type='checkbox' className='form-check-input' checked={listOfRateCodes[v]} onChange={() => changeCheckBox(v)} /></td>
                                                                        <td>{rateCodeNames[v]}</td>
                                                                    </>
                                                            ))}
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : ''
                    }
                </div>
            </div>

            {
                carDetails.length > 0 && table.length > 0 ?
                    <>
                        <div className="row pt-2 mx-2">
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                            From Date
                                        </label>
                                    </div>

                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar fullscreen={false} value={moment(fromDate.valueOf())} onChange={(value) => {
                                            setFromDate(value._d);
                                            setToDate(value._d);
                                        }}
                                            disabledDate={(current) => {
                                                if (toDate && fromDate.valueOf() !== toDate.valueOf()) {
                                                    return current && (current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                                }
                                                else {
                                                    return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="col-md-1 mb-3"></div>

                                    <div className="col-md-1 mb-3">
                                        <label htmlFor="toDate" className="form-label" style={{ marginTop: 5 }}>
                                            To Date
                                        </label>
                                    </div>

                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar fullscreen={false} value={moment(toDate.valueOf())} onChange={(value) => { setToDate(value._d) }}
                                            disabledDate={(current) => {
                                                if (fromDate) {
                                                    return current && current.valueOf() < fromDate.valueOf()
                                                }
                                                else {
                                                    return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mx-2">
                            <div className="col-md-12">
                                {
                                    daysApplicableAccess === 'Yes' &&
                                    <>
                                        <div className="row pt-4">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                                                    Apply Rates to these days only -
                                                </label>
                                            </div>

                                            <div className="col-md-4 mb-3">
                                                <table className="table table-borderless text-left">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={1}><input type='checkbox' className='form-check-input' checked={selectAllDaysFlag} onChange={changeAllDaysCheckBox} /> </th>
                                                            <th colSpan={3}>Select All</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-left">
                                                        {
                                                            daysNameTable.map((val) => (
                                                                <tr>
                                                                    {val.map((v) => (
                                                                        v == null ? '' :
                                                                            <>
                                                                                <td><input type='checkbox' className='form-check-input' checked={daysNameHash[v]} onChange={() => changeDaysCheckBox(v)} /></td>
                                                                                <td>{v}</td>
                                                                                <td>&nbsp;&nbsp;</td>
                                                                            </>
                                                                    ))}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div><strong>Forward Rate Calculator</strong></div>
                                                </div>
                                                <div className="row pt-3">
                                                    <table className="table table-bordered table-condensed  text-center">
                                                        <thead>
                                                            <tr>
                                                                <th><div style={{ width: "5rem" }}>Length of Rental</div></th>
                                                                <th>1</th>
                                                                <th>2</th>
                                                                <th>3</th>
                                                                <th>4</th>
                                                                <th>5</th>
                                                                <th>6</th>
                                                                <th>7</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-left">
                                                            <tr>
                                                                <td><strong>Base Rate</strong></td>
                                                                {
                                                                    Object.keys(reverseCalculatorGrid).map((i) => (
                                                                        <td style={{ verticalAlign: "middle" }}><input type='number' step={"any"} className="form-control" value={reverseCalculatorGrid[i]['price_non_inclusive_of_taxes']}
                                                                            onChange={(e) => changeForwardCalculatorGridValues(e.target.value, i)}
                                                                            style={{ width: "5rem" }} /></td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Total Rate inclusive of taxes</strong></td>
                                                                {
                                                                    Object.keys(reverseCalculatorGrid).map((j) => (
                                                                        <td style={{ verticalAlign: "middle" }}><input type='text' disabled={true} className="form-control" value={reverseCalculatorGrid[j]['price_to_update']} style={{ width: "5rem" }} /></td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {
                                                    reverseCalculatorErrorMessage != '' ?
                                                        <div className="row pt-2">
                                                            <div style={{ color: "red" }}><strong>{reverseCalculatorErrorMessage}</strong></div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>


                        <div className="row mx-2">
                            <div className="col-md-1 mb-3">
                                <button type="submit" className="btn btn-primary" disabled={preloadFlag} onClick={getRateUpdateGrid}>
                                    PreLoad
                                </button>
                            </div>

                            {!(accessType === "View") &&
                                <>
                                    {copyRateAccess === 'Yes' && <>
                                        <div className="col-md-2 mb-3">
                                            <button type="submit" className="btn btn-danger" onClick={openModal} disabled={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key])).length <= 0 ? true : false}>
                                                Copy Rates
                                            </button>
                                        </div>
                                    </>
                                    }
                                </>}

                            {/* <div className="col-md-1">
                                <button type="submit" className="btn btn-danger" onClick={resetGridValues}>
                                    Clear
                                </button>
                            </div>

                            <div className="col-md-2 mb-3 d-flex justify-content-end">
                                {
                                    loading ?
                                        <button className="btn btn-success" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                            Update
                                        </button> :
                                        <button type="submit" className="btn btn-success" onClick={submitData} >
                                            Update
                                        </button>
                                }
                            </div> */}
                        </div>


                        <div className="row pt-4 mx-2">
                            <div className="col-md-12">
                                <table className="table table-bordered table-condensed  text-center">
                                    <thead>
                                        <tr>
                                            <th>Car Class</th>
                                            <th>1 Day</th>
                                            <th>2 Day</th>
                                            <th>3 Day</th>
                                            <th>4 Day</th>
                                            <th>5 Day</th>
                                            <th>6 Day</th>
                                            <th>Daily X-Day</th>
                                            <th>Weekly</th>
                                            <th>Weekly X-day</th>
                                            <th>Monthly</th>
                                            <th>Monthly X-Day</th>
                                            <th>Weekend</th>
                                            <th>Hourly</th>
                                            <th>Minute</th>
                                            <th>Per Mile/Km</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {
                                            carDetails.map((val) => (
                                                <tr>
                                                    <td>{val[0]}</td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_1Day"]} onChange={(e) => changeGridValues(val[0] + "_1Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_2Day"]} onChange={(e) => changeGridValues(val[0] + "_2Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_3Day"]} onChange={(e) => changeGridValues(val[0] + "_3Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_4Day"]} onChange={(e) => changeGridValues(val[0] + "_4Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_5Day"]} onChange={(e) => changeGridValues(val[0] + "_5Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_6Day"]} onChange={(e) => changeGridValues(val[0] + "_6Day", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' disabled={companyName === "Routes Puerto Rico" ? false : true} className="form-control" value={gridDetails[val[0] + "_DailyXDay"]} onChange={(e) => changeGridValues(val[0] + "_DailyXDay", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Weekly"]} onChange={(e) => changeGridValues(val[0] + "_Weekly", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_WeeklyXDay"]} onChange={(e) => changeGridValues(val[0] + "_WeeklyXDay", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Monthly"]} onChange={(e) => changeGridValues(val[0] + "_Monthly", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' className="form-control" value={gridDetails[val[0] + "_MonthlyXDay"]} onChange={(e) => changeGridValues(val[0] + "_MonthlyXDay", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' disabled={weekendRatesShowFlag} className="form-control" value={gridDetails[val[0] + "_Weekend"]} onChange={(e) => changeGridValues(val[0] + "_Weekend", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' disabled={true} className="form-control" value={gridDetails[val[0] + "_Hourly"]} onChange={(e) => changeGridValues(val[0] + "_Hourly", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' disabled={true} className="form-control" value={gridDetails[val[0] + "_Minute"]} onChange={(e) => changeGridValues(val[0] + "_Minute", e.target.value)} style={{ width: "5rem" }} /></td>
                                                    <td><input type='text' disabled={true} className="form-control" value={gridDetails[val[0] + "_PerKmMile"]} onChange={(e) => changeGridValues(val[0] + "_PerKmMile", e.target.value)} style={{ width: "5rem" }} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="row mx-2 row_update">
                            <div className="full_display">
                                <ExportToExcel accountName={companyName.toString()} locationName={locationName.toString()}
                                    rateCodes={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key]))}
                                    fromDate={fromDate} toDate={toDate} carClass={carDetails}
                                    flag={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key])).length <= 0 ? true : false} fileName={"Rate Update"} rateCodeNames={rateCodeNames} />
                            </div>

                            <div className="full_display">
                                <ExportToExcelWithRates accountName={companyName.toString()} locationName={locationName.toString()}
                                    rateCodes={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key]))}
                                    fromDate={fromDate} toDate={toDate} carClass={carDetails} rates={ratesForExcelDownloadWithRates}
                                    flag={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key])).length <= 0 ? true : false} fileName={"Rate Update with Rates"} rateCodeNames={rateCodeNames} />
                            </div>

                            {!(accessType === "View") &&
                                <>
                                    {
                                        excelUploadAccess === 'Yes' &&
                                        <>
                                            <div className="full_display">
                                                <ExcelUpload accountName={companyName.toString()} accountId={company_id.toString()}
                                                    locationName={locationName.toString()} locationId={locationId.toString()}
                                                    rateCodes={Object.keys(listOfRateCodes)}
                                                    fromDate={fromDate} toDate={toDate} carClass={carDetails.map((val) => (val[0]))}
                                                    flag={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key])).length <= 0 ? true : false} />
                                            </div>
                                        </>
                                    }
                                </>}

                            <div className="full_display">
                                {!(accessType === "View") &&
                                    <>
                                        <button type="submit" className="btn btn-danger" onClick={resetGridValues}>
                                            Clear
                                        </button>
                                    </>}
                            </div>
                            {!(accessType === "View") &&
                                <>
                                    <div className="full_display">
                                        {
                                            loading ?
                                                <button className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                    Update
                                                </button> :
                                                <button type="submit" className="btn btn-success" onClick={submitData} >
                                                    Update
                                                </button>
                                        }
                                    </div>
                                </>}
                        </div>
                    </> : ''
            }
        </>
    )
}    