import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { baseURL } from "../backend_url";

const OnlineCheckin = () => {
    const [data, setData] = useState([]); // Set initial state as an array
    const [searchQuery, setSearchQuery] = useState(""); // State for search input

    useEffect(() => {
      axios.post(baseURL + "/get_all_online_checkin")
        .then((res) => {
            setData(res.data);
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        });
    }, []);

    const handleDetailsClick = (session_id) => {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://caledontec.com/doc_scan/get_each_session_details";
        form.target = "_blank"; // Open the form submission in a new tab
    
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "session_id";
        input.value = session_id;
    
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form); // Clean up the form after submission
    };

    // Function to filter data based on search query
    const filteredData = data.filter(item => 
        item.reservation_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.session_id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-3">
                <h1>Online Checkin</h1>
              </div>
            </div>

            {/* Search Box */}
            <div className="row pt-3">
                <div className="col-4"></div>
                <div className="col-4">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Search by reservation number, name, or session ID"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            {/* Table with Filtered Data */}
            <div className="row pt-3">
                <div className="col-8 mt-3">
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Reservation Number</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Session ID</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.reservation_number}</td>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.session_id}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleDetailsClick(item.session_id)}
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        </>
    );
};

export default OnlineCheckin;